import { Flex, Text } from "@chakra-ui/react";

import { IActiveUser } from "@/features/data-transformation/types";

export const EditingMessage = ({ user }: { user: IActiveUser | null }) => {
  if (!user) return null;

  return (
    <Flex className="items-center mr-2 text-sm text-gray-900">
      <Text className="font-semibold mr-0.5">
        {user?.user?.userInfo?.displayName ?? "Someone"}{" "}
      </Text>{" "}
      is currently editing...
    </Flex>
  );
};
