import { isEmpty } from "lodash";

import { isProduction } from "@/utils/baseUrls";

async function parseJSON(response: Response) {
  return await response.json().then((json) => ({
    status: response.status,
    ok: response.ok,
    json,
  }));
}

export const fetchRegionsForEmail = async (email: string) => {
  const code = import.meta.env.VITE_LOGIN_API_CODE;
  const host = window.location.hostname;
  const orgRegEnv = host.replace(".ctnxt.clootrack.com", "").split(".");

  let newHost;
  if (isProduction()) {
    switch (orgRegEnv.length) {
      case 1:
        // eg: ['cus']
        newHost = undefined;
        break;
      case 2:
        // eg: ['org2', 'cus']
        newHost = orgRegEnv.slice(2).join(".");
        break;
      default:
        newHost = undefined;
    }
  } else {
    switch (orgRegEnv.length) {
      // desired url : dev.ctnxt.clootrack.com
      case 1:
        // eg: ['dev']
        newHost = orgRegEnv.join("");
        break;
      case 2:
        // eg: ['cus', 'dev']
        newHost = orgRegEnv.slice(1).join(".");
        break;
      case 3:
        // eg: ['org2', 'cus', 'dev']
        newHost = orgRegEnv.slice(2).join(".");
        break;
      default:
        throw new Error(`Invalid orgRegEnv length: ${orgRegEnv.length}`);
    }
  }

  const url = !isEmpty(newHost)
    ? `https://backend.${newHost}.ctnxt.clootrack.com/api/retrieve_regions?email=${email}&code=${code}`
    : `https://backend.ctnxt.clootrack.com/api/retrieve_regions?email=${email}&code=${code}`;

  try {
    const response = await fetch(url).then(parseJSON);
    console.log("fetch response", response);
    if (response.status !== 200 || !response.ok) {
      throw new Error(
        (response.json.message as string) ?? "Could not fetch regions"
      );
    }
    return response;
  } catch (error) {
    console.log("fetch error", error);
    throw new Error("Could not fetch regions");
  }
};

export const getRedirectUrlAfterLogout = () => {
  const host = window.location.hostname.split(".");
  let redirectDomain = "";
  if (isProduction()) {
    if (host.length === 4) {
      // host = cus.ctnxt.ctk.com => ctnxt.ctk.com
      redirectDomain = host.slice(1).join(".");
    } else {
      // host = org2.cus.ctnxt.ctk.com => ctnxt.ctk.com
      redirectDomain = host.slice(2).join(".");
    }
  } else {
    if (host.length === 6) {
      // host = org2.cus.dev.ctnxt.ctk.com => dev.ctnxt.ctk.com
      redirectDomain = host.slice(2).join(".");
    } else if (host.length === 5) {
      // host = cus.dev.ctnxt.ctk.com => dev.ctnxt.ctk.com
      redirectDomain = host.slice(1).join(".");
    } else {
      // host = dev.ctnxt.ctk.com => dev.ctnxt.ctk.com
      redirectDomain = host.join(".");
    }
  }
  return redirectDomain;
};
