import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([
  "header",
  "headerText",
  "center",
  "footer",
  "container",
]);

export const configurePanelTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: "flex",
      width: "27.5rem",
      height: "47.6875rem",
      padding: "var(--chakra-space-0, 0rem)",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "spacing.0",
      borderRadius: "0.1875rem",
      border: "1px solid  var(--chakra-colors-gray-300)",
      background: "white",
      boxShadow: "8px 8px 0px 0px rgba(0, 0, 0, 0.10)",
    },
    headerText: {
      color: "var(--chakra-colors-gray-1000, #101010)",
      fontSize: "1.5rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "150%",
      letterSpacing: "-0.015rem",
      flex: 1,
      justifyContent: "space-between",
    },
    header: {
      display: "flex",
      padding:
        "var(--chakra-space-6, 1.5rem) var(--chakra-space-6, 1.5rem) var(--chakra-space-4, 1rem) var(--chakra-space-6, 1.5rem)",
      alignItems: "center",
      gap: "var(--chakra-space-6, 1.5rem)",
      alignSelf: "stretch",
      borderBottom: "1px solid var(--chakra-colors-gray-200)",
      background: "white",
    },
    center: {
      display: "flex",
      padding: "1rem",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "var(--chakra-space-4, 1.5rem)",
      flex: "1 0 0",
      alignSelf: "stretch",
      overflowY: "auto",
      maxH: "100%",
    },
    footer: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      alignItems: "center",
      flexDirection: "row",
      padding: "var(--chakra-space-6, 1.5rem)",
      borderTop: "1px solid var(--chakra-colors-gray-200)",
      background: "white",
    },
  },
});
