import { Flex } from "@chakra-ui/layout";
import React from "react";
import { Node } from "reactflow";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalSubHeader,
} from "@/design/components/modal";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";

import { NodeErrorValidationSchema } from "../../types";

import { RunErrorItem } from "./run-error-item";

export type WorkflowErrorSchema = {
  node: Node;
} & NodeErrorValidationSchema;

const RunErrorsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { errors } = useAppSelector(
    (state) => state.rootReducer.modals.modalProps
  );

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={true} size="lg" isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="overflow-hidden max-h-[80vh]">
        <ModalHeader>Failed to run</ModalHeader>
        <ModalSubHeader className="!pl-6 text-sm">
          Could not run node, please check the errors and try again.
        </ModalSubHeader>
        <ModalCloseButton />
        <ModalBody className="max-h-[70vh] overflow-y-auto">
          <Flex className="!px-6 !gap-3 h-full" direction={"column"}>
            {errors.map((error: string) => (
              <RunErrorItem error={error} key={error} />
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RunErrorsModal;
