import { Box, Flex, Radio, RadioGroup, Skeleton } from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import { useEffect, useMemo, useState } from "react";
import { MdReplay, MdTransform } from "react-icons/md";
import { useParams } from "react-router-dom";

import { DataTableIcon } from "@/components/icons/data-table";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@/design/components/form";
import { useGetTaxonomyDatasetsQuery } from "@/features/workflow-studio/api/taxonomy-api";
import { NodeParameter } from "@/features/workflow-studio/types";
import { TaxonomyDatasetDetails } from "@/features/workflow-studio/types/taxonomy-types";

import { PARAMETER_NAMES } from "./theme-mapping";

// Constants

interface ImportTaxonomyProps {
  parameters: NodeParameter[];
  setParameters: (parameters: NodeParameter[]) => void;
  selectedDataset: TaxonomyDatasetDetails | undefined;
  setSelectedDataset: (dataset: TaxonomyDatasetDetails) => void;
}

// Custom Components
const DatasetOption = ({ data, ...props }: any) => (
  <chakraComponents.Option {...props} className="flex-row-reverse !p-0">
    <Flex className="items-center gap-3 grow p-3">
      <Box className="bg-green-50 text-green-500 p-1.5 rounded">
        <DataTableIcon fontSize={24} />
      </Box>
      <Flex className="flex-col">
        <Box className="text-sm font-medium">{data.label}</Box>
        <Flex className="gap-2 items-center">
          <Box className="text-green-500 text-xs font-medium">
            {data.value.rowCount} rows
          </Box>
          {data.value.edaState === "complete" && (
            <Box className="bg-blue-50 text-blue-600 p-1.5 rounded">
              <MdTransform fontSize={14} />
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  </chakraComponents.Option>
);

const SelectPlaceholder = ({ children: _, ...props }: any) => (
  <chakraComponents.Placeholder {...props}>
    <Box className="text-gray-500 font-medium">Select Taxonomy</Box>
  </chakraComponents.Placeholder>
);

export const ImportTaxonomyConfig = ({
  parameters,
  setParameters,
  selectedDataset,
  setSelectedDataset,
}: ImportTaxonomyProps) => {
  const { analysisId } = useParams();
  const [showDatasetSelection, setShowDatasetSelection] =
    useState<boolean>(false);

  const runModeConfigParam = parameters.find(
    (p) => p.name === PARAMETER_NAMES.RUN_MODE_CONFIG
  );

  const runModeConfigValue = JSON.parse(
    (runModeConfigParam?.value as string) ?? "{}"
  );

  const discoverThemesParam =
    runModeConfigValue["theme_discovery_type"] ?? "no_theme_subtheme";

  const alreadySelectedDataset =
    runModeConfigValue["dataset_name"] ?? undefined;

  const datasetError = runModeConfigParam?.errors?.[0]?.includes(
    "Please select a dataset and theme discovery type"
  );

  useEffect(() => {
    if (!alreadySelectedDataset) {
      setShowDatasetSelection(true);
    }
  }, [alreadySelectedDataset]);

  // API Query
  const { datasets, isLoadingDatasets } = useGetTaxonomyDatasetsQuery(
    { analysisId: analysisId as string },
    {
      selectFromResult: ({ data, isLoading }) => ({
        datasets: data?.response?.data?.uploadTaxonomyChoices ?? [],
        isLoadingDatasets: isLoading,
      }),
    }
  );

  // Memoized Values
  const datasetList = useMemo(
    () =>
      datasets.map((dataset) => ({
        label: dataset.displayName,
        value: dataset,
      })),
    [datasets]
  );

  const selectedValue = useMemo(() => {
    return selectedDataset
      ? {
          label: selectedDataset.displayName,
          value: selectedDataset,
        }
      : null;
  }, [selectedDataset]);

  // Event Handlers
  const handleDatasetChange = (selected: any) => {
    setSelectedDataset(selected.value as TaxonomyDatasetDetails);
    setParameters(
      parameters.map((param) => {
        if (param.name === PARAMETER_NAMES.RUN_MODE_CONFIG) {
          return {
            ...param,
            errors: undefined,
            value: JSON.stringify({
              dataset_name: selected?.label,
              is_dataset_configured: true,
              theme_discovery_type:
                runModeConfigValue?.theme_discovery_type ?? "no_theme_subtheme",
            }),
          };
        }
        return param;
      })
    );
  };

  const handleDiscoverThemesChange = (value: string) => {
    setParameters(
      parameters.map((param) =>
        param.name === PARAMETER_NAMES.RUN_MODE_CONFIG
          ? {
              ...param,
              value: JSON.stringify({
                theme_discovery_type: value,
                dataset_name: runModeConfigValue?.dataset_name,
                is_dataset_configured:
                  runModeConfigValue?.is_dataset_configured,
              }),
            }
          : param
      )
    );
  };

  if (isLoadingDatasets)
    return (
      <Flex className="flex-col gap-1">
        <Box className="flex-1 text-sm text-gray-600">Loading Datasets</Box>
        <Skeleton className="h-10 w-full rounded" />
      </Flex>
    );

  return (
    <Box className="pb-32">
      <Flex className="flex-col gap-3 p-4 bg-gray-50 border rounded">
        <FormControl isInvalid={datasetError} isRequired>
          <FormLabel>Import Taxonomy from dataset</FormLabel>
          {showDatasetSelection ? (
            <>
              <Select
                value={selectedValue}
                selectedOptionStyle="check"
                openMenuOnClick={true}
                className="bg-white"
                components={{
                  Placeholder: SelectPlaceholder,
                  Option: DatasetOption,
                }}
                options={datasetList}
                onChange={handleDatasetChange}
              />
              <FormHelperText>
                Dataset must contain "Theme" and "Subtheme" columns.
              </FormHelperText>
              <FormErrorMessage>Taxonomy dataset is required</FormErrorMessage>
            </>
          ) : (
            <Box className="text-sm font-medium bg-gray-100 rounded text-gray-800 p-2 flex items-center gap-2">
              <Box className="underline capitalize grow">
                {alreadySelectedDataset}
              </Box>
              <MdReplay
                className="cursor-pointer hover:text-gray-900 rounded"
                onClick={() => setShowDatasetSelection(true)}
                fontSize={16}
              />
            </Box>
          )}
        </FormControl>
        <FormControl>
          <FormLabel className="font-semibold">Discover new themes?</FormLabel>
          <RadioGroup
            className="ml-4 mt-3"
            onChange={handleDiscoverThemesChange}
            value={discoverThemesParam}
          >
            <Flex className="indent-1 flex-col gap-3 text-sm font-medium">
              <Radio value="no_theme_subtheme">
                Do not add any themes or subthemes
              </Radio>
              <Radio value="both_themes_subthemes">
                Discover new themes & subthemes
              </Radio>
              <Radio value="only_subthemes">Discover new subthemes only</Radio>
            </Flex>
          </RadioGroup>
        </FormControl>
      </Flex>
    </Box>
  );
};
