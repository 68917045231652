import { Box, Flex } from "@chakra-ui/react";

import { ConfigurePanelBody } from "@/design/components/configure-panel";

import { ConfigElements } from "./config-elements";
import ThemeMapping from "./config-forms/dts-config/theme-mapping";
import { useConfigPanel } from "./config-panel-context";

export const NodeConfigPanelBody = () => {
  const { parameters, currentNodeData: node } = useConfigPanel();

  if (!node) return null;
  const isDTSNode =
    node?.name.includes("DTS") ||
    node?.name.includes("Detect Themes and Sentiments");

  return (
    <ConfigurePanelBody>
      {parameters.length === 0 ? (
        <Box className="w-full" textAlign="center">
          There are no parameters to configure.
        </Box>
      ) : (
        <Flex className="w-full h-full" direction="column" gap={3}>
          <ConfigElements />
          {isDTSNode && <ThemeMapping />}
        </Flex>
      )}
    </ConfigurePanelBody>
  );
};
