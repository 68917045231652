import { Flex, Text } from "@chakra-ui/layout";
import React from "react";
import { MdOutlineInfo } from "react-icons/md";
import { Edge, Node } from "reactflow";

import {
  ModalSubHeader,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Modal,
  ModalBody,
} from "@/design/components/modal";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";

import { workflowRunningStatus } from "../../redux";
import { NodeErrorValidationSchema } from "../../types";
import { NODE_STATUS } from "../../utils";
import { validateNodeConfig } from "../../utils/validations";

import { ErrorItem } from "./error-item";

export type WorkflowErrorSchema = {
  node: Node;
} & NodeErrorValidationSchema;

const WorkflowErrorsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { nodes, edges } = useAppSelector(
    (state) => state.rootReducer.modals.modalProps
  );

  const workflowRunStatus = useAppSelector(workflowRunningStatus);
  const isWorkflowPartiallyRunning =
    workflowRunStatus === NODE_STATUS.PARTIALLY_RUNNING;

  const wfErrors: WorkflowErrorSchema[] = nodes
    .map((node: Node) => {
      const { isValid, configErrors, connectionErrors, errors } =
        validateNodeConfig(node, nodes as Node[], edges as Edge[]);
      return {
        node,
        isValid,
        configErrors,
        connectionErrors,
        errors,
      };
    })
    .filter((node: WorkflowErrorSchema) => !node.isValid);

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={true} size="2xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="overflow-hidden max-h-[80vh]">
        <ModalHeader>Run Workflow</ModalHeader>
        <ModalSubHeader className="!pl-6 text-sm">
          Some items in your workflow need to be addressed before running.
        </ModalSubHeader>
        <ModalCloseButton />
        <ModalBody className="max-h-[70vh] overflow-y-auto">
          <Flex className="!px-6 !gap-3 h-full" direction={"column"}>
            {isWorkflowPartiallyRunning && (
              <Flex className="border p-2" direction="column" gap={2} px={3}>
                <Flex
                  align="center"
                  gap={2}
                  overflow="hidden"
                  color={"red.600"}
                  textOverflow="ellipsis"
                >
                  <MdOutlineInfo />
                  <Text whiteSpace="pre" textOverflow="ellipsis">
                    Node executions are going on.
                  </Text>
                </Flex>
                <Text>
                  Wait for node executions to complete or terminate the node
                  executions to run the workflow
                </Text>
              </Flex>
            )}
            {wfErrors.map((wfError) => (
              <ErrorItem
                node={wfError.node}
                isValid={wfError.isValid}
                key={wfError.node.id}
                configErrors={wfError.configErrors}
                connectionErrors={wfError.connectionErrors}
                errors={wfError.errors}
              />
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WorkflowErrorsModal;
