import { isEmpty } from "lodash";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useReactFlow } from "reactflow";

import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import { useWorkflowActions } from "../../hooks/useWorkflowActions";
import {
  selectPanel,
  triggerAutoSave,
  getEditingAllowed,
  getNodeStatus,
} from "../../redux";
import { NodeParameter, NodeType } from "../../types";
import {
  NODE_STATUS,
  NODE_TYPES,
  WORKFLOW_PANELS,
} from "../../utils/constants";
import { ReactFlowInstanceContext } from "../flow-editor";

import { combineParameters } from "./config-utils";

interface ConfigPanelContextType {
  updateNodeParams: (nodeId: string, newParamList: NodeParameter[]) => void;
  autoSave: () => void;
  parameters: NodeParameter[];
  setParameters: (parameters: NodeParameter[]) => void;
  currentNodeId: string | null;
  currentNodeData: NodeType | undefined;
  loadingConfig: boolean;
  setLoadingConfig: (loadingConfig: boolean) => void;
  error: string | null;
  setError: (error: string | null) => void;
  canClosePanel: boolean;
  allowPanelClose: (allowed: boolean) => void;
  isEditingAllowed: boolean;
  setParameterError: (paramId: string, errorMsg: string | null) => void;
}

const ConfigPanelContext = createContext<ConfigPanelContextType | undefined>(
  undefined
);

export const ConfigPanelProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const { setNodes, getNode } = useReactFlow();
  const [parameters, setParameters] = useState<NodeParameter[]>([]);
  const [currentNodeData, setCurrentNodeData] = useState<NodeType>();
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { getWFNodeStatus } = useWorkflowActions();
  const { nodes } = useContext(ReactFlowInstanceContext)!;

  const canClosePanel = useRef(true);

  const allowPanelClose = (allowed: boolean) => {
    canClosePanel.current = allowed;
  };

  const nodeConfigPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.NodeConfigurationPanel)
  ) as { nodeId: string | null; isVisible: boolean };
  const currentNodeRunStatus = useAppSelector(
    getNodeStatus(nodeConfigPanel.nodeId!)
  );

  const currentNodeStatus = getWFNodeStatus(nodeConfigPanel.nodeId);

  const shouldLockNode =
    currentNodeRunStatus?.nodeStatus === NODE_STATUS.RUNNING ||
    currentNodeRunStatus?.isLocked ||
    currentNodeStatus?.status === NODE_STATUS.RUNNING ||
    currentNodeStatus?.isLocked;

  const isEditingAllowed = useAppSelector(getEditingAllowed);

  const selectedNode = nodeConfigPanel.nodeId
    ? nodes.find((node) => node.id === nodeConfigPanel.nodeId)
    : null;
 
  useEffect(() => {
    if (!nodeConfigPanel.nodeId || !selectedNode) return;

    const data = selectedNode.data as NodeType;
    setCurrentNodeData(data);

    setParameters(
      data.parameters.map((p) => ({
        ...p,
        value: p.value ?? p.defaultValue,
      }))
    );

    return () => {
      setCurrentNodeData(undefined);
    };
  }, [nodeConfigPanel, selectedNode, getNode]);

  const setParameterError = (paramId: string, errorMsg: string | null) => {
    setParameters((prevParams) =>
      prevParams.map((param) =>
        param.parameterId === paramId
          ? { ...param, errors: errorMsg ? [errorMsg] : undefined }
          : param
      )
    );
  };

  const updateNodeParams = (nodeId: string, newParamList: NodeParameter[]) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          const nodeData = node.data as NodeType;
          const updatedData = {
            ...nodeData,
            nodeStatus: NODE_STATUS.DEFAULT,
            parameters: combineParameters(newParamList, nodeData.parameters),
          };

          // Update display name for Source nodes
          if (nodeData.nodeType === NODE_TYPES.SOURCE_NODE) {
            const datasetParam = newParamList.find(
              (p) => p.name === "dataset_info"
            );
            if (!isEmpty(datasetParam?.value)) {
              try {
                const datasetInfo = JSON.parse(datasetParam?.value as string);
                updatedData.displayName = datasetInfo.displayName;
              } catch (e) {
                setError("Failed to parse dataset_info");
              }
            }
          }

          node.data = updatedData;
        }
        return node;
      })
    );
  };

  const autoSave = () => {
    setTimeout(() => {
      dispatch(triggerAutoSave());
    }, 300);
  };

  const value = useMemo(
    () => ({
      updateNodeParams,
      autoSave,
      parameters,
      setParameters,
      setParameterError,
      currentNodeId: nodeConfigPanel.nodeId,
      currentNodeData,
      loadingConfig,
      setLoadingConfig,
      error,
      setError,
      canClosePanel: canClosePanel.current,
      allowPanelClose,
      isEditingAllowed: (isEditingAllowed && !shouldLockNode) ?? false,
    }),
    [
      parameters,
      nodeConfigPanel,
      currentNodeData,
      loadingConfig,
      error,
      canClosePanel.current,
      isEditingAllowed,
      shouldLockNode,
    ]
  );

  return (
    <ConfigPanelContext.Provider value={value}>
      {children}
    </ConfigPanelContext.Provider>
  );
};

export const useConfigPanel = () => {
  const context = useContext(ConfigPanelContext);
  if (!context) {
    throw new Error("useConfigPanel must be used within ConfigPanelProvider");
  }
  return context;
};
