import { useEffect, useRef, useState } from "react";

import { useShowToast } from "@/components/toast";
import { useAppDispatch } from "@/reduxHooks";

import {
  useTaxonomyStatusQuery,
  useUploadTaxonomyMutation,
} from "../api/taxonomy-api";
import { validateDTSParams } from "../components/config-panel/config-forms/dts-config/dts-utils";
import { hidePanel } from "../redux";
import { NodeParameter } from "../types";
import { TaxonomyDatasetDetails } from "../types/taxonomy-types";
import { WORKFLOW_PANELS } from "../utils";

import { useWorkflowActions } from "./useWorkflowActions";

interface UseTaxonomyActionsProps {
  currentNodeId: string | null;
  parameters: NodeParameter[];
  updateNodeParams: (nodeId: string, params: NodeParameter[]) => void;
  autoSave: () => void;
  taxonomyId?: string;
  nodeUsageInstanceId?: string;
  selectedDataset?: TaxonomyDatasetDetails;
  setParameters: (params: NodeParameter[]) => void;
}

export const useTaxonomyActions = ({
  currentNodeId,
  parameters,
  setParameters,
  updateNodeParams,
  autoSave,
  taxonomyId,
  nodeUsageInstanceId,
  selectedDataset,
}: UseTaxonomyActionsProps) => {
  const dispatch = useAppDispatch();
  const [uploadTaxonomy] = useUploadTaxonomyMutation();
  const {
    fetchAndUpdateWFNodesOnly,
    updateParamsForSingleNode,
    saveWFandUpdateNodes,
  } = useWorkflowActions();

  const [uploading, setUploading] = useState(false);
  const shouldPollRef = useRef(false);
  const toast = useShowToast(undefined, undefined, true);
  const { data: statusData, isError } = useTaxonomyStatusQuery(
    {
      taxonomyId: taxonomyId ?? "",
    },
    { skip: !shouldPollRef.current, pollingInterval: 2000 }
  );
  const runModeParam = parameters.find((p) => p.name === "Run Mode");

  useEffect(() => {
    if (isError || statusData?.data?.results[0].status === "failed") {
      toast({
        title: "Failed to load taxonomy",
        status: "error",
      });
      shouldPollRef.current = false;
      setUploading(false);
    }

    if (statusData?.data?.results[0].status === "completed") {
      shouldPollRef.current = false;
      fetchAndUpdateWFNodesOnly()
        .then(() => {
          setUploading(false);
          dispatch(hidePanel(WORKFLOW_PANELS.NodeConfigurationPanel));
        })
        .catch((error) => {
          console.error("Failed to update workflow nodes:", error);
        });
    }
  }, [statusData, dispatch, isError]);

  const handleNewThemeMode = (nodeId: string, params: NodeParameter[]) => {
    updateNodeParams(nodeId, params);
    dispatch(hidePanel(WORKFLOW_PANELS.NodeConfigurationPanel));
    autoSave();
  };

  const handleUploadTaxonomyMode = async (
    params: NodeParameter[],
    targetTaxonomyId: string
  ) => {
    setUploading(true);
    const metaDataParam = params.find((p) => p.name === "Run Mode Config");
    if (!metaDataParam?.value || !currentNodeId) return;

    await updateParamsForSingleNode(currentNodeId, params);
    await saveWFandUpdateNodes();

    // if dataset is not changed, just save and close
    if (!selectedDataset) {
      setUploading(false);
      dispatch(hidePanel(WORKFLOW_PANELS.NodeConfigurationPanel));
      return;
    }

    try {
      await uploadTaxonomy({
        datasetDetails: selectedDataset,
        isDatasetChanged: selectedDataset !== undefined,
        metadata: {
          node_usage_instance_id: nodeUsageInstanceId,
          theme_discovery_type: metaDataParam.value.theme_discovery_type,
        },
        taxonomyId: targetTaxonomyId,
      }).unwrap();

      shouldPollRef.current = true;
    } catch (error) {
      toast({
        title: "Failed to upload taxonomy",
        status: "error",
      });
      setUploading(false);
      console.error("Failed to upload taxonomy:", error);
    }
  };

  const handleSave = async () => {
    const { finalParamList, finalHasErrors, isNewThemeMode } =
      validateDTSParams(parameters, runModeParam);

    setParameters(finalParamList);
    if (finalHasErrors || !currentNodeId) return;

    // ---- IF run mode is FIND NEW THEMES
    if (isNewThemeMode) {
      handleNewThemeMode(currentNodeId, finalParamList);
      return;
    }

    // ---- IF run mode is UPLOAD TAXONOMY
    if (!taxonomyId) return;
    await handleUploadTaxonomyMode(finalParamList, taxonomyId);
  };

  return {
    handleSave,
    uploading,
    isComplete: statusData?.data?.results[0].status === "completed",
  };
};
