import { Flex, Text } from "@chakra-ui/react";
import { MdOutlineInfo } from "react-icons/md";

import { Icon } from "@/design/components/icon";

export const RunErrorItem = ({ error }: { error: string }) => {
  return (
    <Flex className="border p-2" direction="column" gap={2} px={3}>
      <Flex
        align="center"
        gap={2}
        overflow="hidden"
        color={"red.600"}
        textOverflow="ellipsis"
      >
        <Icon as={MdOutlineInfo} />
        <Text className="text-base">{error}</Text>
      </Flex>
    </Flex>
  );
};
