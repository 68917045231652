import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { formatDistanceToNow } from "date-fns";
import { useContext, useMemo, useState } from "react";
import { MdOutlineErrorOutline, MdWarningAmber } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useReactFlow } from "reactflow";
import clsx from "clsx";

import { ToastType, useShowToast } from "@/components/toast";
import { ConfigurePanelBody } from "@/design/components/configure-panel";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";

import { selectPanel, hidePanel } from "../..";
import { 
  useGetWFActiveUserQuery, 
  useFixWorkflowMutation,
  useLazyGetWorkflowQuery 
} from "../../api/workflow-api";
import { currentWorkflowId, workflowStatus, setworkflowStatus } from "../../redux/workflow-slice";
import { EventType, WorkflowEvent, WorkflowNodeSchema } from "../../types/workflow-types";
import { NodeType } from "../../types";
import { WORKFLOW_PANELS } from "../../utils/constants";
import { ReactFlowInstanceContext } from "../flow-editor";
import { ConvertFlowtoWorkflowPayload } from "../../utils/transform-response";
import { updateNodeWithLatestData } from "../../utils/update-nodes";
import { useWorkflowActions } from "../../hooks/useWorkflowActions";

const formatEventName = (name: string): string => {
  return name
    .replace(/_/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const formatEventDate = (timestamp: number): string => {
  try {
    // Convert milliseconds timestamp to Date object
    const date = new Date(timestamp);
    return formatDistanceToNow(date, { addSuffix: true });
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date';
  }
};

export const LogsPanelBody = () => {
  const dispatch = useAppDispatch();
  const toast = useShowToast();
  const { toObject } = useReactFlow();
  const currentWorkflowStatus = useAppSelector(workflowStatus);
  const params = useParams();
  const logsPanel = useAppSelector(selectPanel(WORKFLOW_PANELS.LogsPanel));
  const workflowId = useAppSelector(currentWorkflowId);
  const { setNodesFn } = useContext(ReactFlowInstanceContext)!;
  const { fetchAndUpdateWorkflow } = useWorkflowActions();

  const [fixWorkflow] = useFixWorkflowMutation();
  const [getWorkflow] = useLazyGetWorkflowQuery();
  const [loadingEventName, setLoadingEventName] = useState<string | null>(null);

  const { data: userData, isLoading, error, refetch: refetchUserData } = useGetWFActiveUserQuery(
    { analysisId: params.analysisId! },
    { skip: !params.analysisId }
  );

  const events = useMemo<WorkflowEvent[]>(() => {
    if (!userData?.data?.results?.events) {
      return [];
    }
    return userData.data.results.events;
  }, [userData]);

  const handleFixEvent = async (event: WorkflowEvent) => {
    setLoadingEventName(event.eventName);
    
    if (!workflowId || !params.analysisId) {
      toast({
        title: "Error",
        description: "Workflow ID or Analysis ID is missing",
        status: ToastType.Error,
      });
      setLoadingEventName(null);
      return;
    }

    try {
      // First get the current workflow data
      const workflowResponse = await getWorkflow({
        workflowId
      }).unwrap();

      const currentWorkflow = workflowResponse.response.data?.workflows[0];
      
      if (!currentWorkflow) {
        toast({
          title: "Error",
          description: "Could not get current workflow data",
          status: ToastType.Error,
        });
        return;
      }

      // Get the current ReactFlow instance and convert it to the proper workflow payload format
      const instance = toObject();
      const workflowData = ConvertFlowtoWorkflowPayload(instance, currentWorkflow.workflowNodes);

      // Call fix workflow with the properly structured data
      const fixResponse = await fixWorkflow({
        analysisId: params.analysisId,
        workflowId,
        workflow: workflowData
      }).unwrap();

      // Get the updated workflow data after fix
      const updatedWorkflow = fixResponse.response.data?.workflows[0];
      if (updatedWorkflow) {
        // Update nodes with the new workflow data
        setNodesFn((nds) => nds.map((node) => {
          const updatedNode = updatedWorkflow.workflowNodes.find(
            (n: WorkflowNodeSchema) => n.workflowNodeId === (node.data as NodeType).workflowNodeId
          );
          if (updatedNode) {
            return updateNodeWithLatestData(node, updatedNode);
          }
          return node;
        }));

        // Update workflow status in Redux store
        dispatch(setworkflowStatus({
          workflowRunId: updatedWorkflow.workflowRunId ?? null,
          workflowRunStatus: updatedWorkflow.workflowStatus,
          workflowNodeStatus: updatedWorkflow.workflowNodes.map(node => ({
            workflowNodeId: node.workflowNodeId!,
            uiNodeId: node.uiNodeId,
            nodeVersionId: node.nodeVersionId!,
            nodeUsageInstanceId: node.nodeUsageInstanceId!,
            nodeStatus: node.nodeStatus,
            nodeType: node.nodeType,
            message: ""
          })),
          startTime: null,
          endTime: null,
          message: "",
          terminateWorkflowEnabled: false
        }));

        // After successful fix, trigger a refetch of active user data to get updated events
        await fetchAndUpdateWorkflow();

        // Get the latest events after the update
        const updatedUserData = await refetchUserData();
        const updatedEvents = updatedUserData?.data?.data?.results?.events || [];
        
        // Check if there are any remaining blocking events
        const hasRemainingBlockers = updatedEvents.some(
          (e: WorkflowEvent) => e.eventType === EventType.ACTIONABLE_BLOCKER
        );

        // Only close the panel if there are no more blocking events
        if (!hasRemainingBlockers) {
          dispatch(hidePanel(WORKFLOW_PANELS.LogsPanel));
        }

        toast({
          title: "Fix Applied",
          description: "The workflow has been updated successfully",
          status: ToastType.Success,
        });
      }
    } catch (error) {
      console.error('Fix workflow error:', error);
      toast({
        title: "Error",
        description: "Failed to apply fix to the workflow",
        status: ToastType.Error,
      });
    } finally {
      setLoadingEventName(null);
    }
  };

  if (isLoading) {
    return (
      <ConfigurePanelBody overflowY="auto">
        <Flex
          className="bg-gray-50 !w-full p-3 rounded-md gap-3 !self-center"
          align="center"
          justify="center"
        >
          <Spinner size="sm" className="text-gray-600" />
          <Text className="text-gray-600">Loading logs...</Text>
        </Flex>
      </ConfigurePanelBody>
    );
  }

  if (error) {
    return (
      <ConfigurePanelBody overflowY="auto">
        <Flex
          className="bg-red-50 !w-full p-3 rounded-md gap-3 !self-center border border-red-200"
          align="center"
          justify="center"
        >
          <MdOutlineErrorOutline size={20} className="text-red-500" />
          <Text className="text-red-600">Failed to load logs</Text>
        </Flex>
      </ConfigurePanelBody>
    );
  }

  if (events.length === 0) {
  return (
    <ConfigurePanelBody overflowY="auto">
        <Flex
          className="bg-gray-50 !w-full p-3 rounded-md gap-3 !self-center border border-gray-200"
          align="center"
          justify="center"
        >
          <MdOutlineErrorOutline size={20} className="text-gray-400" />
          <Text className="text-gray-600">No logs available</Text>
        </Flex>
      </ConfigurePanelBody>
    );
  }

  return (
    <ConfigurePanelBody overflowY="auto" className="p-2">
      {events.map((event, index) => {
        const isFixingThisEvent = loadingEventName === event.eventName;
        console.log('Rendering event:', JSON.stringify(event, null, 2));
        return (
          <Box
            key={index}
            className={clsx(
              "w-full p-3 rounded-md mb-2 border shadow-sm",
              event.eventType === EventType.INFORMATIONAL && "bg-white border-gray-200",
              event.eventType === EventType.ACTIONABLE_WARNING && "bg-yellow-50 border-yellow-300",
              event.eventType === EventType.ACTIONABLE_BLOCKER && "bg-red-50 border-red-300"
            )}
          >
            <Flex direction="column" gap={3}>
              <Flex justify="space-between" align="center">
                <Flex align="center" gap={2}>
                  {event.eventType !== EventType.INFORMATIONAL && (
                    <MdWarningAmber 
                      size={18} 
                      className={clsx(
                        event.eventType === EventType.ACTIONABLE_WARNING && "text-yellow-600",
                        event.eventType === EventType.ACTIONABLE_BLOCKER && "text-red-600"
                      )} 
                    />
                  )}
                  <Text 
                    className={clsx(
                      "font-medium",
                      event.eventType === EventType.INFORMATIONAL && "text-gray-700",
                      event.eventType === EventType.ACTIONABLE_WARNING && "text-yellow-700",
                      event.eventType === EventType.ACTIONABLE_BLOCKER && "text-red-700"
                    )}
                  >
                    {formatEventName(event.eventName)}
                  </Text>
                </Flex>
                <Text className="text-xs text-gray-500">
                  {formatEventDate(event.createdOn)}
                </Text>
              </Flex>
              
              <Text className="text-sm text-gray-700 font-medium">
                {event.eventMessage}
              </Text>

              {event.eventType !== EventType.INFORMATIONAL && (
                <Flex justify="flex-end" className="mt-1">
                  <Button
                    size="sm"
                    onClick={() => handleFixEvent(event)}
                    leftIcon={<MdWarningAmber />}
                    isLoading={isFixingThisEvent}
                    className={clsx(
                      "!px-4 !py-1 !h-8 !text-sm !font-medium",
                      event.eventType === EventType.ACTIONABLE_WARNING && 
                        "!bg-yellow-100 !text-yellow-700 hover:!bg-yellow-200 !border !border-yellow-300",
                      event.eventType === EventType.ACTIONABLE_BLOCKER && 
                        "!bg-red-100 !text-red-700 hover:!bg-red-200 !border !border-red-300"
                    )}
                  >
                    Fix Issue
                  </Button>
                </Flex>
              )}
          </Flex>
          </Box>
        );
      })}
    </ConfigurePanelBody>
  );
};
