import { Box, Button, Flex, IconButton, Slide } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  MdsCloseRound,
  MdsDragHandleRound,
} from "react-icons-with-materialsymbols/mds";
import { Edge, Node, getIncomers, useReactFlow } from "reactflow";

import { Icon } from "@/design/components/icon";
import { TabList, TabPanel, TabPanels, Tabs } from "@/design/components/tabs";
import { useShowTransformPanel } from "@/features/workflow-studio/hooks";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";

import { selectPanel } from "../..";
import { getEditingAllowed, getNodeStatus, hidePanel } from "../../redux";
import { NodeIO, NodeType } from "../../types";
import { WorkflowNodeRelationsSchema } from "../../types/workflow-types";
import { NODE_STATUS, UNIQUE_NODES, WORKFLOW_PANELS } from "../../utils/constants";

import { DataPreviewTab } from "./data-preview-tab";
import { DataPreviewTable } from "./data-preview-table";

export const DataPreviewPanel = () => {
  const { getNode, getNodes, getEdges } = useReactFlow();
  const dispatch = useAppDispatch();
  const dataPreviewPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.DataPreviewPanel)
  );

  const {
    showTransformPanel,
    addTransformNode,
    isLoading: isTransformNodeLoading,
  } = useShowTransformPanel();

  const currentNode = getNode(dataPreviewPanel.nodeId as string);

  const sliderRef = useRef<HTMLDivElement>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const isEditingAllowed = useAppSelector(getEditingAllowed);

  const [panelHeight, setPanelHeight] = useState(256); // Default height 256px
  const isDraggingRef = useRef(false);
  const startYRef = useRef(0);
  const startHeightRef = useRef(0);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      isDraggingRef.current = true;
      startYRef.current = e.clientY;
      startHeightRef.current = panelHeight;
      document.body.style.cursor = "grabbing";
      document.body.style.userSelect = "none";
    },
    [panelHeight]
  );

  const currentNodeRunStatus = useAppSelector(getNodeStatus(dataPreviewPanel.nodeId as string));
  const shouldLockNode =
    currentNodeRunStatus?.nodeStatus === NODE_STATUS.RUNNING ||
    currentNodeRunStatus?.isLocked;

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (!isDraggingRef.current) return;

    const deltaY = startYRef.current - e.clientY;
    const newHeight = Math.max(
      256,
      Math.min(window.innerHeight - 64, startHeightRef.current + deltaY)
    );
    setPanelHeight(newHeight);
  }, []);

  const handleMouseUp = useCallback(() => {
    isDraggingRef.current = false;
    document.body.style.cursor = "";
    document.body.style.userSelect = "";
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  const isUnionNode = () => {
    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const nodeData = getNode(dataPreviewPanel.nodeId as string)
      .data as NodeType;
    return (
      nodeData.name.toUpperCase().includes("UNION") ||
      nodeData.name?.toUpperCase().includes("APPEND")
    );
  };

  const [inputTabs, edgeVsNodeMap] = useMemo(() => {
    if (!dataPreviewPanel.nodeId || !currentNode) return [];
    // get the incoming nodes of the current node and
    // find the edges with incoming nodes as source and current node as target

    const incomingNodes = getIncomers(
      currentNode as Node,
      getNodes(),
      getEdges()
    );

    const incomingNodeIds = incomingNodes.map((node) => node.id);

    const incomingEdges = getEdges().filter(
      (edge) =>
        edge.target === dataPreviewPanel.nodeId &&
        incomingNodeIds.includes(edge.source)
    );

    const edgeVsNodeList: Array<{
      edge: string;
      nodeTitle: string;
      nodeId: string;
    }> = [];

    incomingEdges.forEach((edge) => {
      const node = getNode(edge.source) as Node<NodeType>;
      edgeVsNodeList.push({
        edge: edge.data.targetNodeIoId,
        nodeId: node.id,
        nodeTitle: node.data.displayName ?? "Input",
      });
    });

    const incomingEdgesData = incomingEdges.map((edge) => {
      const edgeData = edge.data as WorkflowNodeRelationsSchema;
      return edgeData.targetNodeIoId;
    });

    return [incomingEdgesData, edgeVsNodeList];
  }, [getEdges, dataPreviewPanel.nodeId, currentNode, getNodes]);

  const outputTabs: string[] = useMemo(() => {
    if (!dataPreviewPanel.nodeId) return [];
    const currentNodeOutputIds =
      currentNode?.data.outputs.map((output: NodeIO) => output.ioDetailId) ??
      [];
    return currentNodeOutputIds;
  }, [dataPreviewPanel.nodeId, currentNode]);

  /*
    Determines if user is in input or output tab and based on the results searches for transfrom node
    If in input tab it searches for transfrom node before the current node
    If in output tab it searches for transform node after current tab
     */
  const geTransformNodeIfConnected = (): Node<NodeType> | undefined => {
    const isInputTab = tabIndex < edgeVsNodeMap!.length;

    let edgeKey: keyof Edge = "source";
    let nodeKey: keyof Edge = "target";
    if (isInputTab) {
      edgeKey = "target";
      nodeKey = "source";
    }

    const edges = getEdges();

    const getOutputEdgesForNode = edges.filter(
      (edge) => edge[edgeKey] === dataPreviewPanel.nodeId
    );

    const outputNodes = getOutputEdgesForNode.map((edge) => {
      const node = getNode(edge[nodeKey] as string) as Node<NodeType>;
      return node;
    });

    const transformNode = outputNodes.find(
      (node) => node.data.displayName === UNIQUE_NODES.TRANSFORM
    );

    return transformNode;
  };
  const transformData = () => {
    if (!isEditingAllowed) return;
    const transformNode = geTransformNodeIfConnected();
    const hasTransformNode = !!transformNode;

    if (hasTransformNode) {
      showTransformPanel({ transformNodeUiId: transformNode?.id });
    } else {
      const isInputTab = tabIndex < edgeVsNodeMap!.length;
      const currentNodeId = isInputTab
        ? edgeVsNodeMap![tabIndex].nodeId
        : (dataPreviewPanel.nodeId as string);
      const _currentNode = getNode(currentNodeId)!;

      const _newTransformNode = addTransformNode(_currentNode);

      // TODO: Find a fix for nodes not updaitng instantly
      setTimeout(
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        () => showTransformPanel({ transformNodeUiId: _newTransformNode?.id }),
        10
      );
    }
  };

  const onClose = () => {
    dispatch(hidePanel(WORKFLOW_PANELS.DataPreviewPanel));
  };

  useEffect(() => {
    // Set initial tab to first output tab by using the length of input tabs
    setTabIndex(edgeVsNodeMap?.length ?? 0);
  }, [dataPreviewPanel.nodeId, edgeVsNodeMap?.length]);

  if (!inputTabs) return null;
  return (
    <Slide
      className="!absolute bottom-0 !right-0 !w-full"
      ref={sliderRef}
      direction="bottom"
      in={dataPreviewPanel.isVisible}
      style={{
        zIndex: 999,
        height: `${panelHeight}px`,
        transition: isDraggingRef.current ? "none" : "height 0.2s ease-in-out",
      }}
      unmountOnExit
    >
      <Box
        className="absolute top-0 w-full select-none"
        onMouseDown={handleMouseDown}
      >
        <Box
          className="h-1 flex items-center justify-center bg-gray-200 border-t cursor-row-resize"
          role="presentation"
        >
          <div className="w-10 h-3 flex justify-center items-center z-20 !cursor-grab focus:!cursor-grabbing active:!cursor-grabbing border border-gray-400 bg-white shadow-md rounded-full">
            <MdsDragHandleRound fontSize={18} className="!p-0 stroke-[22]" />
          </div>
        </Box>
      </Box>

      <Box className="h-full bg-white border-gray-800 border-t">
        <Tabs
          key={"tab" + dataPreviewPanel.nodeId}
          onChange={(index) => setTabIndex(index)}
          index={tabIndex}
          variant="line"
          size="sm"
          isLazy
          className="h-full"
        >
          <Flex className="border-b pr-3" align="center" gap={2}>
            <TabList className="grow max-w-full overflow-x-auto overflow-y-hidden">
              {edgeVsNodeMap!.map((tab, idx) => {
                return (
                  <DataPreviewTab key={"ip" + idx + dataPreviewPanel.nodeId}>
                    {tab.nodeTitle}
                  </DataPreviewTab>
                );
              })}
              {outputTabs.map((_tab, _idx) => {
                return (
                  <DataPreviewTab key={"op" + dataPreviewPanel.nodeId}>
                    {"Output"}
                  </DataPreviewTab>
                );
              })}
            </TabList>
            <Flex className="items-center gap-x-5">
              <Button
                colorScheme="secondary"
                isDisabled={!isEditingAllowed || shouldLockNode}
                isLoading={isTransformNodeLoading}
                onClick={transformData}
                size="sm"
                title={shouldLockNode ? "Node is running" : undefined}
                variant="solid"
              >
                Transform Data
              </Button>
              <IconButton
                aria-label="close"
                colorScheme="secondary"
                icon={<Icon as={MdsCloseRound} size="lg" />}
                onClick={onClose}
                size="lg"
                variant="ghost"
              />
            </Flex>
          </Flex>

          <TabPanels>
            {edgeVsNodeMap!.map((tab, idx) => {
              return (
                <TabPanel key={"ip" + idx + tab.edge}>
                  <DataPreviewTable
                    panelHeight={panelHeight}
                    ioDetailId={tab.edge}
                    type={"input"}
                    tabIndex={isUnionNode() ? idx : 0}
                    ref={sliderRef}
                  />
                </TabPanel>
              );
            })}
            {outputTabs.map((tab, idx) => {
              return (
                <TabPanel key={"op" + idx + tab}>
                  <DataPreviewTable
                    panelHeight={panelHeight}
                    ioDetailId={tab}
                    type={"output"}
                    tabIndex={0}
                    ref={sliderRef}
                  />
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Box>
    </Slide>
  );
};
