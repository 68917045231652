import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { useReactFlow } from "reactflow";

import { TOAST_DURATION, ToastType, useShowToast } from "@/components/toast";
import { useAppDispatch } from "@/reduxHooks";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import { EXECUTION_TYPE } from "@/utils/enums";

import {
  useRunNodeMutation,
  useTerminateNodeRunMutation,
} from "../api/workflow-api";
import {
  setReferenceRunId,
  setWorkflowRunId,
  setWorkflowRunStatus,
} from "../redux/workflow-slice";
import { WorkflowNodeSchema } from "../types/workflow-types";
import { NODE_STATUS } from "../utils/constants";
import { ConvertFlowtoWorkflowPayload } from "../utils/transform-response";

export const useRunNode = () => {
  const { toObject } = useReactFlow();
  const dispatch = useAppDispatch();
  const params = useParams();
  const toast = useShowToast();
  const [runNode, { isLoading }] = useRunNodeMutation();
  const [terminateNodeRun, { isLoading: isTerminating }] =
    useTerminateNodeRunMutation();

  const runFromNode = async (
    startNodeId: string,
    executionType: string | EXECUTION_TYPE,
    { latestNodes }: { latestNodes?: WorkflowNodeSchema[] } = {}
  ) => {
    const obj = toObject();

    try {
      toast({
        title: "Initiating Node Run",
        id: startNodeId,
        status: ToastType.Loading,
        duration: null,
      });
      const res = await runNode({
        analysisId: params.analysisId!,
        workflowId: params.editorId!,
        executionType: executionType,
        startNodeId: startNodeId,
        workflow: ConvertFlowtoWorkflowPayload(obj, latestNodes),
      }).unwrap();
      const wfStatus = res.response.data?.workflows[0];
      if (wfStatus && wfStatus.workflowStatus) {
        dispatch(setWorkflowRunStatus(wfStatus.workflowStatus));
        dispatch(setWorkflowRunId(wfStatus.workflowRunId as string));
        dispatch(setReferenceRunId(wfStatus.referenceRunId as string));
      }
      if (wfStatus?.workflowStatus === NODE_STATUS.PARTIALLY_RUNNING) {
        toast.update(startNodeId, {
          title: "Node Run initiated",
          isClosable: true,
          status: ToastType.Success,
          duration: TOAST_DURATION.MEDIUM,
        });
      }
      if (wfStatus?.workflowStatus === NODE_STATUS.SUCCESS) {
        if (toast.isActive(startNodeId)) {
          toast.close(startNodeId);
        }
        toast({
          title: "Node Run completed",
          status: ToastType.Success,
          duration: TOAST_DURATION.MEDIUM,
        });
      }

      const nodeValidations = wfStatus?.validations?.nodeValidations;
      const errors = Object.values(nodeValidations || {}).flatMap(
        (validation) => [
          ...(validation?.message ?? []),
          ...(validation?.validationMessage ?? []),
        ]
      );
      if (
        !isEmpty(errors) ||
        wfStatus?.workflowStatus === NODE_STATUS.FAILED ||
        wfStatus?.workflowStatus === NODE_STATUS.DEFAULT
      ) {
        toast.close(startNodeId);
        dispatch(
          openModal({
            modalType: ModalTypes.RUN_ERRORS,
            modalProps: { errors },
          })
        );
      }
    } catch (err) {
      console.log(err);
      toast.update(startNodeId, {
        title: "Failed to execute node",
        status: ToastType.Error,
        duration: TOAST_DURATION.MEDIUM,
      });
    }
  };

  const terminateNodeRunFromNode = async (workflowNodeId: string) => {
    try {
      toast({
        title: "Terminating Node Run",
        id: workflowNodeId,
        status: ToastType.Loading,
        duration: null,
      });
      await terminateNodeRun({
        workflowId: params.editorId!,
        workflowNodeId: workflowNodeId,
      }).unwrap();
      toast.update(workflowNodeId, {
        title: "Node Run terminated",
        status: ToastType.Success,
        duration: TOAST_DURATION.MEDIUM,
      });
    } catch (err) {
      toast.update(workflowNodeId, {
        title: "Failed to terminate node run",
        status: ToastType.Error,
        duration: TOAST_DURATION.MEDIUM,
      });
    }
  };

  return {
    runFromNode,
    isLoading,
    terminateNodeRunFromNode,
    isTerminating,
  };
};
