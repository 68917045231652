import { Box, Flex, Tooltip } from "@chakra-ui/react";
import clsx from "clsx";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  MdArrowRight,
  MdCancel,
  MdOutlineErrorOutline,
  MdOutlineMessage,
} from "react-icons/md";
import { useParams } from "react-router-dom";
import { Edge, Node } from "reactflow";

import { useShowToast } from "@/components/toast";
import { Button } from "@/design/components/button";
import { IconButton } from "@/design/components/icon-button";
import { NodeType } from "@/features/workflow-studio/types";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice";

import { showPanel, selectPanel } from "../..";
import {
  useGetWorkflowRunStatusQuery,
  useLazyGetWorkflowQuery,
  useGetWFActiveUserQuery,
} from "../../api/workflow-api";
import { useRunWorkflow } from "../../hooks/useRunWorkflow";
import { useWorkflowActions } from "../../hooks/useWorkflowActions";
import { useWorkflowRunTrigger } from "../../hooks/workflowRunTrigger";
import {
  currentWfActiveUser,
  currentWorkflowId,
  currentWorkflowRunId,
  getEditingAllowed,
  getWorkflowState,
  setEditingAllowed,
  setWorkflowRunStatus,
  setworkflowStatus,
  workflowStatus as wfRunStatus,
  workflowRunningStatus,
} from "../../redux/workflow-slice";
import {
  WorkflowNodeSchema,
  WorkflowSchema,
  WorkflowStatusSchema,
  EventType,
  WorkflowEvent,
} from "../../types/workflow-types";
import { updateNodeParameters } from "../../utils";
import {
  NODE_STATUS,
  NODE_STATUS_ICONS,
  WORKFLOW_PANELS,
} from "../../utils/constants";
import { validateNodeConfig } from "../../utils/validations";
import { ReactFlowInstanceContext } from "../flow-editor";

export const RunAndLogButtons = ({
  hasEditAccess,
  nodes,
  edges,
  runStatusQuery,
}: {
  hasEditAccess: boolean;
  nodes: Node[];
  edges: Edge[];
  runStatusQuery: any;
}) => {
  const dispatch = useAppDispatch();
  const toast = useShowToast();
  const failureCountRef = useRef(0);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const params = useParams();
  const workflowId = useAppSelector(currentWorkflowId);
  const workflowRunId = useAppSelector(currentWorkflowRunId);
  const currentWFRunStatus = useAppSelector(workflowRunningStatus);
  const wfnodeStatus = useAppSelector(wfRunStatus);
  const { setNodesFn } = useContext(ReactFlowInstanceContext)!;
  const { runWorkflow, isLoading } = useRunWorkflow();
  const { fetchAndUpdateWorkflow } = useWorkflowActions();
  const currentUser = useAppSelector(currentWfActiveUser);
  const workflowState = useAppSelector(getWorkflowState);
  const [getWorkflowDetails] = useLazyGetWorkflowQuery();
  const isEditingAllowed = useAppSelector(getEditingAllowed) ?? false;
  const logsPanel = useAppSelector(selectPanel(WORKFLOW_PANELS.LogsPanel));
  const { data: activeUserData } = useGetWFActiveUserQuery(
    { analysisId: params.analysisId! },
    { skip: !params.analysisId }
  );

  const isWorkflowInProgress =
    currentWFRunStatus === NODE_STATUS.RUNNING ||
    currentWFRunStatus === NODE_STATUS.CANCELLING;

  const isWorkflowPartiallyRunning =
    currentWFRunStatus === NODE_STATUS.PARTIALLY_RUNNING;

  const { data: userData, refetch, error, isError } = runStatusQuery;

  const workflowErrors = nodes.filter(
    (node: Node) => !validateNodeConfig(node, nodes, edges).isValid
  );
  const hasError = useMemo(
    () => isWorkflowPartiallyRunning || workflowErrors.length > 0,
    [workflowErrors, isWorkflowPartiallyRunning]
  );

  const hasBlockingEvents = useMemo(() => {
    return (
      activeUserData?.data?.results?.events?.some(
        (event: WorkflowEvent) =>
          event.eventType === EventType.ACTIONABLE_BLOCKER
      ) ?? false
    );
  }, [activeUserData?.data?.results?.events]);

  const isDisabled = useMemo(
    () =>
      !isEditingAllowed ||
      isLoading ||
      nodes.length === 0 ||
      (hasBlockingEvents && logsPanel.isVisible),
    [
      isLoading,
      nodes.length,
      isEditingAllowed,
      hasBlockingEvents,
      logsPanel.isVisible,
    ]
  );

  const isTerminateEnabled =
    (hasEditAccess && (isLoading || wfnodeStatus?.terminateWorkflowEnabled)) ??
    false;

  const notificationStatus = useMemo(() => {
    const events =
      (activeUserData?.data?.results?.events as WorkflowEvent[]) || [];
    const hasBlocker = events.some(
      (event) => event.eventType === EventType.ACTIONABLE_BLOCKER
    );
    const hasWarning = events.some(
      (event) => event.eventType === EventType.ACTIONABLE_WARNING
    );

    if (hasBlocker) return "blocker";
    if (hasWarning) return "warning";
    return null;
  }, [activeUserData?.data?.results?.events]);

  const updateNodes = (nds: Node[], workflowNodes: WorkflowNodeSchema[]) => {
    return nds.map((node) => {
      const newNode = workflowNodes.find(
        (n: WorkflowNodeSchema) =>
          n.workflowNodeId === (node.data as NodeType).workflowNodeId
      );
      if (newNode) {
        return {
          ...node,
          data: {
            ...node.data,
            parameters: updateNodeParameters(node, newNode.nodeParameters),
            nodeStatus: newNode.nodeStatus,
            isOutput: newNode.isOutput,
            outputName: newNode.outputName,
            outputState: newNode.outputState,
            isOutputDataAvailable: newNode.isOutputDataAvailable,
            isLocked: newNode.isLocked,
          } as NodeType,
        };
      }
      return node;
    });
  };

  const updateNodeAfterRunStopped = (workflowStatus: WorkflowStatusSchema) => {
    // this function is different from updateNodesAfterRunSuccess
    // because it updates the node status only based on run_status api response.
    setNodesFn((nds: Node[]) =>
      nds.map((node: Node) => {
        const nodeInfo = workflowStatus.workflowNodeStatus.find(
          (status) =>
            status.workflowNodeId === (node.data as NodeType).workflowNodeId
        );
        if (nodeInfo) {
          return {
            ...node,
            data: {
              ...node.data,
              nodeStatus: nodeInfo.nodeStatus,
            } as NodeType,
          };
        }
        return node;
      })
    );
  };

  const updateNodesAfterRunSuccess = async () => {
    const response = await getWorkflowDetails({
      workflowId: params.editorId!,
    }).unwrap();
    const wf = response.response.data?.workflows[0] as WorkflowSchema;
    const workflowNodes = wf.workflowNodes;
    if (wf.workflowStatus === NODE_STATUS.RUNNING || workflowNodes.length <= 0)
      return;
    setNodesFn((nds) => updateNodes(nds, workflowNodes));
  };

  // Effect to handle API failures
  useEffect(() => {
    if (isError && error) {
      console.log("error", failureCountRef.current);
      failureCountRef.current += 1;
      if (failureCountRef.current >= 5) {
        fetchAndUpdateWorkflow().catch((e) => console.error(e));
        failureCountRef.current = 0; // Reset counter after handling
      }
    } else if (!isError && !error) {
      failureCountRef.current = 0;
    }
  }, [isError]);

  console.log("currentWFRunStatus", currentWFRunStatus);
  console.log(
    "userData",
    userData?.response?.data?.workflowAndNodeStatus?.workflowRunStatus
  );

  // update store on data change
  useEffect(() => {
    if (userData) {
      const workflowStatusinResponse = userData?.response?.data
        ?.workflowAndNodeStatus as WorkflowStatusSchema;

      switch (workflowStatusinResponse?.workflowRunStatus) {
        case NODE_STATUS.SUCCESS:
          toast({
            title: "Workflow run completed successfully",
            status: "success",
          });
          updateNodesAfterRunSuccess().catch((e) => console.error(e));
          break;
        case NODE_STATUS.FAILED:
          toast({
            title: "Workflow Run Failed",
            status: "error",
          });
          updateNodeAfterRunStopped(workflowStatusinResponse);
          break;
        case NODE_STATUS.DEFAULT:
        case NODE_STATUS.CANCELLED:
          if (currentWFRunStatus === NODE_STATUS.RUNNING) {
            console.log(
              "calling onTerminate => ",
              currentWFRunStatus,
              workflowStatusinResponse?.workflowRunStatus
            );
            onTerminate();
          }
          // had added this to refetch the run_status api
          // since there was a delay in updating the node status in  the run_status response
          // NOTE: commenting it out now becuase run_status api is supposed to give updated status
          // refetch();
          updateNodeAfterRunStopped(workflowStatusinResponse);
          break;
      }
      dispatch(setworkflowStatus(workflowStatusinResponse));
      dispatch(
        setWorkflowRunStatus(workflowStatusinResponse.workflowRunStatus)
      );
    }
  }, [userData]);

  const openworkflowErrorsModal = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.WORKFLOW_ERRORS,
        modalProps: {
          nodes: nodes,
          edges: edges,
        },
      })
    );
  };

  useWorkflowRunTrigger(hasError, openworkflowErrorsModal);

  const onTerminate = () => {
    toast({
      title: "Run terminated successfully",
    });
    dispatch(setEditingAllowed(true));
  };

  const terminateWorkflow = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.CONFIRM_TERMINATE,
        modalProps: {
          currentUser,
          workflowState: workflowState,
          workflowId: params.editorId!,
          workflowRunId: workflowRunId!,
        },
      })
    );
  };

  const openLogsPanel = () => {
    dispatch(showPanel({ panel: WORKFLOW_PANELS.LogsPanel }));
  };
  const workflowStatusText = useMemo(() => {
    if (currentWFRunStatus === NODE_STATUS.CANCELLING) {
      return {
        disabled: "Cancelling Workflow",
        running: "Terminating Workflow",
      };
    }
    return {
      disabled: "Building workflow, please wait...",
      running: "Running Workflow",
    };
  }, [currentWFRunStatus]);

  const onRunClicked = () => {
    if (hasError) {
      openworkflowErrorsModal();
    } else {
      failureCountRef.current = 0;
      runWorkflow.bind(null, workflowId!, {})();
    }
  };

  return (
    <Flex align="center" gap={1}>
      <Box pos="relative">
        <IconButton
          aria-label="view logs"
          variant="ghost"
          size="sm"
          colorScheme="dark"
          onClick={openLogsPanel}
          icon={<MdOutlineMessage />}
        />
        {notificationStatus && (
          <Box
            className={clsx(
              notificationStatus === "blocker" && "bg-red-500",
              notificationStatus === "warning" && "bg-yellow-500"
            )}
            pos="absolute"
            top="-1px"
            right="-1px"
            w="8px"
            h="8px"
            borderRadius="full"
          />
        )}
      </Box>
      {isWorkflowInProgress ? (
        <Flex className="gap-2 pr-2" align="center" justify={"end"}>
          <Tooltip
            isDisabled={isTerminateEnabled}
            label={workflowStatusText.disabled}
            placement="bottom"
          >
            <Button
              className="ml-2 disabled:hover:!bg-red-400"
              onClick={terminateWorkflow}
              size="sm"
              isDisabled={!isTerminateEnabled}
              variant="outline"
              isLoading={isLoading}
              color="red.600"
              colorScheme="customRed"
              rightIcon={<MdCancel />}
            >
              Terminate Run
            </Button>
          </Tooltip>
          <Box className="text-gray-800 pb-1">
            {NODE_STATUS_ICONS[currentWFRunStatus as NODE_STATUS].icon}
          </Box>
          <Box className="text-gray-800 text-xs">
            {workflowStatusText.running}
          </Box>
        </Flex>
      ) : (
        <Tooltip
          hasArrow
          isDisabled={isEditingAllowed}
          label="Cannot run workflow in View Mode."
          placement="bottom"
        >
          <Button
            ref={buttonRef}
            onClick={onRunClicked}
            size="sm"
            className="disabled:cursor-not-allowed"
            variant={hasError ? "outline" : "solid"}
            isLoading={isLoading}
            isDisabled={isDisabled}
            colorScheme="dark"
            rightIcon={hasError ? <MdOutlineErrorOutline /> : <MdArrowRight />}
          >
            Run WorkFlow
          </Button>
        </Tooltip>
      )}
    </Flex>
  );
};
