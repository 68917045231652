import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { DashboardState, PowerBIError } from '../types'

interface DashboardSliceState {
  dashboardState: DashboardState | null
  isLoading: boolean
  error: PowerBIError | null
  hasDashboard: boolean
}

const initialState: DashboardSliceState = {
  dashboardState: null,
  isLoading: true,
  error: null,
  hasDashboard: false
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardState: (state, action: PayloadAction<DashboardState | null>) => {
      state.dashboardState = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setError: (state, action: PayloadAction<PowerBIError | null>) => {
      state.error = action.payload
    },
    setHasDashboard: (state, action: PayloadAction<boolean>) => {
      state.hasDashboard = action.payload
    },
    resetDashboard: (state) => {
      state.dashboardState = null
      state.isLoading = true
      state.error = null
      state.hasDashboard = true
    }
  }
})

export const {
  setDashboardState,
  setLoading,
  setError,
  setHasDashboard,
  resetDashboard
} = dashboardSlice.actions

export default dashboardSlice.reducer 