import { Node } from "reactflow";

import { NodeType } from "../types";
import { WorkflowNodeSchema } from "../types/workflow-types";

export const updateNodeParameters = (node: Node, newNodeParams: any[] = []) => {
  const nodeData = node.data as NodeType;
  
  // Handle case where parameters might be undefined
  if (!nodeData?.parameters) {
    console.warn(`Node ${node.id} has no parameters defined`);
    return [];
  }

  return nodeData.parameters.map((param) => {
    const matchedParam = newNodeParams.find(
      (np) => np.id === param.parameterId
    );
    return {
      ...param,
      value: matchedParam ? matchedParam.value : undefined,
    };
  });
};

export const updateNodeWithLatestData = (
  currentNode: Node,
  updatedNodeData: WorkflowNodeSchema
): Node => {
  // Early return if node data is invalid
  if (!currentNode?.data) {
    console.warn('Invalid node data structure');
    return currentNode;
  }

  try {
    return {
      ...currentNode,
      data: {
        ...currentNode.data,
        workflowNodeId: updatedNodeData.workflowNodeId ?? currentNode.data.workflowNodeId,
        nodeUsageInstanceId: updatedNodeData.nodeUsageInstanceId ?? currentNode.data.nodeUsageInstanceId,
        nodeVersionId: updatedNodeData.nodeVersionId ?? currentNode.data.nodeVersionId,
        name: updatedNodeData.name ?? currentNode.data.name,
        displayName: updatedNodeData.displayName ?? updatedNodeData.name ?? currentNode.data.displayName,
        nodeType: updatedNodeData.nodeType ?? currentNode.data.nodeType,
        description: updatedNodeData.description ?? currentNode.data.description,
        nodeStatus: updatedNodeData.nodeStatus,
        parameters: updateNodeParameters(currentNode, updatedNodeData.nodeParameters),
        nodeColumnMappings: updatedNodeData.nodeColumnMappings ?? currentNode.data.nodeColumnMappings,
        isOutput: updatedNodeData.isOutput,
        outputName: updatedNodeData.outputName,
        outputState: updatedNodeData.outputState,
        isOutputDataAvailable: updatedNodeData.isOutputDataAvailable,
        parentNodeUiId: updatedNodeData.parentNodeUiId,
      } as NodeType,
    };
  } catch (error) {
    console.error('Error updating node data:', error);
    return currentNode;
  }
};

export const updateNodesAfterSave = (
  newNodes: Array<WorkflowNodeSchema>,
  currentNodes: Array<Node>
): Array<Node> => {
  if (!Array.isArray(newNodes) || !Array.isArray(currentNodes)) {
    console.warn('Invalid input arrays for updateNodesAfterSave');
    return currentNodes;
  }

  try {
    return currentNodes.map((node) => {
      const newNode = newNodes.find((n) => n.uiNodeId === node.id) ?? null;
      if (newNode) {
        return updateNodeWithLatestData(node, newNode);
      }
      return node;
    });
  } catch (error) {
    console.error('Error updating nodes after save:', error);
    return currentNodes;
  }
};

export const updateNodeIdAfterRun = (
  workflowNodes: WorkflowNodeSchema[],
  setNodes: (updater: (nodes: Node[]) => Node[]) => void
) => {
  if (!Array.isArray(workflowNodes)) {
    console.warn('Invalid workflow nodes array');
    return;
  }

  try {
    setNodes((currentNodes) => updateNodesAfterSave(workflowNodes, currentNodes));
  } catch (error) {
    console.error('Error updating node IDs after run:', error);
  }
};
