import { isEmpty } from "lodash";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useReactFlow } from "reactflow";

import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import { selectPanel, triggerAutoSave } from "../../redux";
import { NodeParameter, NodeType } from "../../types";
import {
  NODE_STATUS,
  NODE_TYPES,
  WORKFLOW_PANELS,
} from "../../utils/constants";

import { combineParameters } from "./config-utils";

interface ConfigPanelContextType {
  updateNodeParams: (nodeId: string, newParamList: NodeParameter[]) => void;
  autoSave: () => void;
  parameters: NodeParameter[];
  setParameters: (parameters: NodeParameter[]) => void;
  currentNodeId: string | null;
  currentNodeData: NodeType | undefined;
  loadingConfig: boolean;
  setLoadingConfig: (loadingConfig: boolean) => void;
  error: string | null;
  setError: (error: string | null) => void;
}

const ConfigPanelContext = createContext<ConfigPanelContextType | undefined>(
  undefined
);

export const ConfigPanelProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const { setNodes, getNode } = useReactFlow();
  const [parameters, setParameters] = useState<NodeParameter[]>([]);
  const [currentNodeData, setCurrentNodeData] = useState<NodeType>();
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const nodeConfigPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.NodeConfigurationPanel)
  ) as { nodeId: string | null; isVisible: boolean };

  useEffect(() => {
    if (!nodeConfigPanel.nodeId) return;
    const node = getNode(nodeConfigPanel.nodeId);
    if (!node) return;

    const data = node.data as NodeType;
    setCurrentNodeData(data);

    setParameters(
      data.parameters.map((p) => ({
        ...p,
        value: p.value ?? p.defaultValue,
      }))
    );

    return () => {
      setCurrentNodeData(undefined);
    };
  }, [nodeConfigPanel, getNode]);

  const updateNodeParams = (nodeId: string, newParamList: NodeParameter[]) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          const nodeData = node.data as NodeType;
          const updatedData = {
            ...nodeData,
            nodeStatus: NODE_STATUS.DEFAULT,
            parameters: combineParameters(newParamList, nodeData.parameters),
          };

          // Update display name for Source nodes
          if (nodeData.nodeType === NODE_TYPES.SOURCE_NODE) {
            const datasetParam = newParamList.find(
              (p) => p.name === "dataset_info"
            );
            if (!isEmpty(datasetParam?.value)) {
              try {
                const datasetInfo = JSON.parse(datasetParam?.value as string);
                updatedData.displayName = datasetInfo.displayName;
              } catch (e) {
                setError("Failed to parse dataset_info");
              }
            }
          }

          node.data = updatedData;
        }
        return node;
      })
    );
  };

  const autoSave = () => {
    setTimeout(() => {
      dispatch(triggerAutoSave());
    }, 300);
  };

  const value = useMemo(
    () => ({
      updateNodeParams,
      autoSave,
      parameters,
      setParameters,
      currentNodeId: nodeConfigPanel.nodeId,
      currentNodeData,
      loadingConfig,
      setLoadingConfig,
      error,
      setError,
    }),
    [parameters, nodeConfigPanel, currentNodeData, loadingConfig, error]
  );

  return (
    <ConfigPanelContext.Provider value={value}>
      {children}
    </ConfigPanelContext.Provider>
  );
};

export const useConfigPanel = () => {
  const context = useContext(ConfigPanelContext);
  if (!context) {
    throw new Error("useConfigPanel must be used within ConfigPanelProvider");
  }
  return context;
};
