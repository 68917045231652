import { isEmpty } from "lodash";

import { NodeParameter } from "@/features/workflow-studio/types";

import { getValidatedParams } from "../../config-utils";

import { RUN_MODES, PARAMETER_NAMES } from "./theme-mapping";

export const validateDTSParams = (
  parameters: NodeParameter[],
  runModeParam: NodeParameter | undefined
) => {
  const { updatedParamList } = getValidatedParams(parameters);
  const runMode = runModeParam?.value;
  const isNewThemeMode = runMode === RUN_MODES.FIND_NEW_THEMES;

  if (isNewThemeMode) {
    // if isNewThemeMode, remove errors from TAXONOMY_FILE_PATH and RUN_MODE_CONFIG
    const finalParamList = updatedParamList.map((param) => {
      if (
        param.name === PARAMETER_NAMES.TAXONOMY_FILE_PATH ||
        param.name === PARAMETER_NAMES.RUN_MODE_CONFIG
      ) {
        return { ...param, errors: undefined };
      }
      return param;
    });

    // Recalculate hasErrors based on remaining parameters
    const finalHasErrors = finalParamList.some(
      (param) =>
        param.name !== PARAMETER_NAMES.TAXONOMY_FILE_PATH &&
        param.name !== PARAMETER_NAMES.RUN_MODE_CONFIG &&
        !isEmpty(param?.errors)
    );
    return { finalParamList, finalHasErrors, isNewThemeMode };
  } else {
    // if isUploadTaxonomyMode, then check if PARAMETER_NAMES.RUN_MODE_CONFIG has dataset_name, is_dataset_configured, theme_discovery_type
    const uploadTaxonomyParamList = updatedParamList.map((param) => {
      if (param.name === PARAMETER_NAMES.TAXONOMY_FILE_PATH) {
        return {
          ...param,
          errors: undefined,
        };
      } else if (param.name === PARAMETER_NAMES.RUN_MODE_CONFIG) {
        const parsedValue = JSON.parse(param.value as string);
        if (
          !parsedValue?.dataset_name ||
          !parsedValue?.is_dataset_configured ||
          !parsedValue?.theme_discovery_type
        ) {
          return {
            ...param,
            errors: ["Please select a dataset and theme discovery type"],
          };
        }
      }
      return param;
    });

    const uploadTaxonomyHasErrors = uploadTaxonomyParamList.some(
      (param) =>
        param.name !== PARAMETER_NAMES.TAXONOMY_FILE_PATH &&
        param.name !== PARAMETER_NAMES.RUN_MODE_CONFIG &&
        !isEmpty(param?.errors)
    );
    return {
      finalParamList: uploadTaxonomyParamList,
      finalHasErrors: uploadTaxonomyHasErrors,
      isNewThemeMode,
    };
  }
};
