import { getMSBaseUrl } from "@/utils/baseUrls.ts";
import { MICRO_SERVICES } from "@/utils/enums.ts";

export const WorkflowEndpoints = {
  createWorkflowFromAnalysis: (analysisId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.WORKFLOW_MANAGER
    )}/api/v1.0/analysis/${analysisId}/workflows/`,
  getWorkflowFromAnalysis: (analysisId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.WORKFLOW_MANAGER
    )}/api/v1.0/analysis/${analysisId}/workflows/`,
  getWorkflow: (workFlowId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.WORKFLOW_MANAGER
    )}/api/v1.0/workflows/${workFlowId}`,
  saveWorkflow: (workFlowId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.WORKFLOW_MANAGER
    )}/api/v1.0/workflows/${workFlowId}?action=save`,
  fixWorkflow: (workFlowId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.WORKFLOW_MANAGER
    )}/api/v1.0/workflows/${workFlowId}?action=fix`,
  runWorkflow: (workFlowId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.WORKFLOW_MANAGER
    )}/api/v1.0/workflows/${workFlowId}?action=run`,
  markOutput: (workFlowId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.WORKFLOW_MANAGER
    )}/api/v1.0/workflows/${workFlowId}?action=mark_output`,
  unMarkOutput: (workFlowId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.WORKFLOW_MANAGER
    )}/api/v1.0/workflows/${workFlowId}?action=unmark_output`,
  getWorkflowStatus: (workFlowId: string, workflowRunId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.WORKFLOW_MANAGER
    )}/api/v1.0/workflows/${workFlowId}/run_status/`,
  terminateWorkflow: (workFlowId: string, workflowRunId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.WORKFLOW_MANAGER
    )}/api/v1.0/workflows/${workFlowId}/terminate/${workflowRunId}`,
  wfEditAccess: (analysisId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.WORKFLOW_MANAGER
    )}/api/v1.0/analysis/${analysisId}/workflow/user/`,
  getWorkflowEditStatus: (analysisId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.WORKFLOW_MANAGER
    )}/api/v1.0/analysis/${analysisId}/workflow/edit_status/`,
};

export const NodeManagerEndpoints = {
  getNodesList: () =>
    `${getMSBaseUrl(MICRO_SERVICES.NODE_MANAGER)}/api/v1.0/nodes/`,
  getNode: (nodeId: string, nodeVersionId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.NODE_MANAGER
    )}/api/v1.0/nodes/${nodeId}?version_id=${nodeVersionId}&mode=compact`,
  getNodesFromUsageInstanceId: () =>
    `${getMSBaseUrl(MICRO_SERVICES.NODE_MANAGER)}/api/v1.0/get-nodes/`,
  getFlows: (flowType: string, limit?: number, offset?: number) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.NODE_MANAGER
    )}/api/v1.0/flows/?view=${flowType}&limit=${limit}&offset=${offset}`,
  getFlow: (flowId: string) =>
    `${getMSBaseUrl(MICRO_SERVICES.NODE_MANAGER)}/api/v1.0/flows/${flowId}`,
  createFlow: () =>
    `${getMSBaseUrl(MICRO_SERVICES.NODE_MANAGER)}/api/v1.0/flows/`,
  editFlow: (flowId: string) =>
    `${getMSBaseUrl(MICRO_SERVICES.NODE_MANAGER)}/api/v1.0/flows/${flowId}`,
  deleteFlow: (flowId: string) =>
    `${getMSBaseUrl(MICRO_SERVICES.NODE_MANAGER)}/api/v1.0/flows/${flowId}`,
  markFlowAsFavorite: (flowId: string, flowVersionId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.NODE_MANAGER
    )}/api/v1.0/flows/${flowId}?version_id=${flowVersionId}&action=mark_favorite`,
  unMarkFlowAsFavorite: (flowId: string, flowVersionId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.NODE_MANAGER
    )}/api/v1.0/flows/${flowId}?version_id=${flowVersionId}&action=unmark_favorite`,
  shareFlow: (flowId: string, flowVersionId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.NODE_MANAGER
    )}/api/v1.0/flows/${flowId}?version_id=${flowVersionId}&action=share`,

  //
  getNodelistWithAnalysisId: (analysisId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.NODE_MANAGER
    )}/api/v1.0/nodes/?analysis_id=${analysisId}`,
  getNodesFromUsageInstanceIdWithAnalysisId: (analysisId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.NODE_MANAGER
    )}/api/v1.0/get-nodes/?analysis_id=${analysisId}`,
};

export const NodeDataPreviewEndpoints = {
  getNodeDataPreview: () =>
    `${getMSBaseUrl(MICRO_SERVICES.PDS)}/api/v1.0/preview-io/`,
  exportDataset: () =>
    `${getMSBaseUrl(MICRO_SERVICES.PDS)}/api/v1.0/export-dataset/`,
  downloadData: () =>
    `${getMSBaseUrl(MICRO_SERVICES.PDS)}/api/v1.0/download-dataset/`,
  downloadStatus: () =>
    `${getMSBaseUrl(MICRO_SERVICES.PDS)}/api/v1.0/conversion-status/`,
};

export const TaxonomyEndpoints = {
  getTaxonomy: (analysisId: string) =>
    `${getMSBaseUrl(
      MICRO_SERVICES.TAXONOMY
    )}/api/v1.0/upload_taxonomy_choices/${analysisId}`,
  createTaxonomy: () =>
    `${getMSBaseUrl(MICRO_SERVICES.TAXONOMY)}/api/v1.0/taxonomy-master`,
  uploadTaxonomy: (taxonomyId: string) =>
    `${getMSBaseUrl(MICRO_SERVICES.TAXONOMY)}/api/v1.0/taxonomy/${taxonomyId}/?action_type=upload_taxonomy`,
  taxonomyStatus: (taxonomyId: string) =>
    `${getMSBaseUrl(MICRO_SERVICES.TAXONOMY)}/api/v1.0/taxonomy/${taxonomyId}/task_status/?action_type=upload_taxonomy`,
};
