import { useParams } from "react-router-dom";
import { useReactFlow } from "reactflow";

import { useShowToast } from "@/components/toast";
import { useAppDispatch } from "@/reduxHooks";
import { WF_ACCESS_MODE } from "@/utils/enums";

import {
  useLazyGetWFActiveUserQuery,
  useRunWorkflowMutation,
} from "../api/workflow-api";
import { hideAllPanels } from "../redux";
import {
  setCurrentWfActiveUser,
  setEditingAllowed,
  setWorkflowAccessMode,
  setWorkflowRunId,
  setWorkflowRunStatus,
  setWorkflowState,
} from "../redux/workflow-slice";
import { WorkflowNodeSchema } from "../types/workflow-types";
import { NODE_STATUS } from "../utils/constants";
import { updateNodeIdAfterRun } from "../utils/index";
import { ConvertFlowtoWorkflowPayload } from "../utils/transform-response";

import { useWorkflowActions } from "./useWorkflowActions";

export const useRunWorkflow = () => {
  const dispatch = useAppDispatch();
  const { toObject, setNodes } = useReactFlow();
  const params = useParams();
  const toast = useShowToast();
  const [runWorkflow, { isLoading }] = useRunWorkflowMutation();
  const [refetchUser] = useLazyGetWFActiveUserQuery();
  const { fetchAndUpdateWorkflow } = useWorkflowActions();
  const handleRunWorkflow = async (
    workflowId: string,
    { latestNodes }: { latestNodes?: WorkflowNodeSchema[] } = {}
  ) => {
    const obj = toObject();
    dispatch(setEditingAllowed(false));
    dispatch(hideAllPanels());
    const nodes = toObject().nodes;
    nodes.forEach((node) => {
      return {
        ...node,
        selected: false,
      };
    });
    setNodes(nodes);

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const res = await runWorkflow({
        analysisId: params.analysisId!,
        workflowId: workflowId,
        workflow: ConvertFlowtoWorkflowPayload(obj, latestNodes),
      }).unwrap();

      const wfStatus = res.response.data?.workflows[0];
      if (wfStatus?.workflowStatus === NODE_STATUS.RUNNING) {
        updateNodeIdAfterRun(wfStatus.workflowNodes, setNodes);
        toast({
          title: "Workflow run initiated successfully",
          status: "success",
        });
        dispatch(setWorkflowAccessMode(WF_ACCESS_MODE.LOCKOUT));
        dispatch(setWorkflowRunStatus(wfStatus.workflowStatus));
        dispatch(setWorkflowRunId(wfStatus.workflowRunId as string));
        const response = await refetchUser({
          analysisId: params.analysisId!,
        }).unwrap();
        const results = response?.data?.results;
        dispatch(setCurrentWfActiveUser(results?.activeUser));
        dispatch(setWorkflowState(results?.workflowState));
      }
    } catch (err) {
      fetchAndUpdateWorkflow()
        .then(() => {
          toast({
            title: "Failed to run workflow",
            status: "error",
          });
          dispatch(setEditingAllowed(true));
        })
        .catch((e) => console.error(e));
    }
  };

  return {
    runWorkflow: handleRunWorkflow,
    isLoading,
  };
};
