import { ApiResponse } from "@/types";

import {
  TaxonomyChoiceResponse,
  TaxonomyDatasetDetails,
  TaxonomyStatusResponse,
  UploadTaxonomyResponse,
} from "../types/taxonomy-types";

import { workflowEditorApi } from "./api";
import { TaxonomyEndpoints } from "./api-endpoints";

export const taxonomyApi = workflowEditorApi.injectEndpoints({
  endpoints: (build) => ({
    getTaxonomyDatasets: build.query<
      ApiResponse<TaxonomyChoiceResponse>,
      { analysisId: string }
    >({
      query: ({ analysisId }) => ({
        url: TaxonomyEndpoints.getTaxonomy(analysisId),
        method: "GET",
        ignoreBaseUrl: true,
      }),
      keepUnusedDataFor: 0,
      providesTags: ["Taxonomy-datasets"],
    }),
    createTaxonomy: build.mutation<
      ApiResponse<UploadTaxonomyResponse>,
      { workflowId: string; analysisId: string; workflowNodeId: string }
    >({
      query: ({ workflowId, analysisId, workflowNodeId }) => ({
        url: TaxonomyEndpoints.createTaxonomy(),
        method: "POST",
        body: {
          workflow_id: workflowId,
          analysis_id: analysisId,
          workflow_node_id: workflowNodeId,
        },
        ignoreBaseUrl: true,
      }),
    }),
    uploadTaxonomy: build.mutation<
      ApiResponse<UploadTaxonomyResponse>,
      {
        taxonomyId: string;
        isDatasetChanged: boolean;
        datasetDetails: TaxonomyDatasetDetails;
        metadata: any;
      }
    >({
      query: ({ taxonomyId, isDatasetChanged, datasetDetails, metadata }) => ({
        url: TaxonomyEndpoints.uploadTaxonomy(taxonomyId),
        method: "POST",
        body: {
          is_dataset_changed: isDatasetChanged,
          dataset_details: datasetDetails,
          metadata,
        },
        ignoreBaseUrl: true,
      }),
    }),
    taxonomyStatus: build.query<TaxonomyStatusResponse, { taxonomyId: string }>(
      {
        query: ({ taxonomyId }) => ({
          url: TaxonomyEndpoints.taxonomyStatus(taxonomyId),
          method: "GET",
          ignoreBaseUrl: true,
        }),
      }
    ),
  }),
});

export const {
  useGetTaxonomyDatasetsQuery,
  useLazyGetTaxonomyDatasetsQuery,
  useCreateTaxonomyMutation,
  useUploadTaxonomyMutation,
  useTaxonomyStatusQuery,
  useLazyTaxonomyStatusQuery,
} = taxonomyApi;
