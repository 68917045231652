import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UploadSliceState {
  selectedFile: File | null
  isUploading: boolean
  uploadSuccess: boolean
}

const initialState: UploadSliceState = {
  selectedFile: null,
  isUploading: false,
  uploadSuccess: false
}

const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setSelectedFile: (state, action: PayloadAction<File | null>) => {
      state.selectedFile = action.payload
    },
    setUploading: (state, action: PayloadAction<boolean>) => {
      state.isUploading = action.payload
    },
    setUploadSuccess: (state, action: PayloadAction<boolean>) => {
      state.uploadSuccess = action.payload
    },
    resetUpload: (state) => {
      state.selectedFile = null
      state.isUploading = false
      state.uploadSuccess = false
    }
  }
})

export const {
  setSelectedFile,
  setUploading,
  setUploadSuccess,
  resetUpload
} = uploadSlice.actions

export default uploadSlice.reducer 