import { useMsal } from "@azure/msal-react";
import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { MdsTransformRound } from "react-icons-with-materialsymbols/mds";
import { useDispatch, useSelector } from "react-redux";

import { ScheduleFilled } from "@/components/icons/schedule-fill.tsx";
import { useShowToast } from "@/components/toast";
import { Icon } from "@/design/components/icon";
import {
  ACCESS_MODE,
  activeUser,
  EdaMetaDataContext,
  ORIGIN,
  setEdaMetaData,
} from "@/features/data-transformation";
import {
  useLazyGetReadonlyModeStatusQuery,
  useStartTransformingMutation,
} from "@/features/data-transformation/api";
import { selectPanel } from "@/features/workflow-studio";
import { useWorkflowActions } from "@/features/workflow-studio/hooks/useWorkflowActions";
import {
  getEditingAllowed,
  getNodeStatus,
  workflowRunningStatus,
} from "@/features/workflow-studio/redux/workflow-slice";
import { NODE_STATUS, WORKFLOW_PANELS } from "@/features/workflow-studio/utils";
import { useAppSelector } from "@/reduxHooks";

type PopoverMessageButtonProps = {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  disabled?: boolean;
};
export const PopoverMessageButton = ({
  isOpen,
  onClose,
  onOpen,
  disabled,
}: PopoverMessageButtonProps) => {
  const dispatch = useDispatch();

  const { instance } = useMsal();
  const toast = useShowToast(undefined, undefined, true);

  const { edaId, analysisId, edaFrom } = useContext(EdaMetaDataContext);
  const currentActiveUser = useSelector(activeUser);

  const isEDAFromWorkflow = edaFrom === ORIGIN.Workflow;
  const isWFEditAllowed = useAppSelector(getEditingAllowed);
  const wfRunStatus = useAppSelector(workflowRunningStatus);

  const { getWFNodeStatus } = useWorkflowActions();

  const dataTransformationPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.DataTransformationPanel)
  );
  const isWorkflowRunning = wfRunStatus === NODE_STATUS.RUNNING;

  const currentNodeRunStatus = useAppSelector(
    getNodeStatus(dataTransformationPanel.nodeId ?? "")
  );
  const currentNodeStatus = getWFNodeStatus(dataTransformationPanel.nodeId);
  const shouldLockNode =
    currentNodeRunStatus?.nodeStatus === NODE_STATUS.RUNNING ||
    currentNodeRunStatus?.isLocked ||
    currentNodeStatus?.status === NODE_STATUS.RUNNING ||
    currentNodeStatus?.isLocked;

  const isTranformDisabled = useMemo(() => {
    if (isEDAFromWorkflow && shouldLockNode) return true;

    const wfrunning = isEDAFromWorkflow && !isWFEditAllowed;
    if (wfrunning || currentActiveUser !== null) {
      return true;
    }
    return false;
  }, [isEDAFromWorkflow, isWFEditAllowed, currentActiveUser, shouldLockNode]);

  const [startEditing, { isLoading }] = useStartTransformingMutation();
  const [refreshAPI, { isFetching: loadingStatus }] =
    useLazyGetReadonlyModeStatusQuery();

  const { popOverTitle, popOverDescription } = useMemo(() => {
    if (isEDAFromWorkflow && isWorkflowRunning) {
      return {
        popOverTitle: "Workflow is running",
        popOverDescription:
          "Cannot transform while workflow is running. Please come back later once the workflow is finished.",
      };
    } else if (isEDAFromWorkflow && !isWFEditAllowed) {
      return {
        popOverTitle: "You have view only access to this workflow",
        popOverDescription:
          "You cannot transform this dataset while you have view only access to this workflow.",
      };
    } else if (currentActiveUser !== null) {
      return {
        popOverTitle: "Come back later",
        popOverDescription:
          "Another user is currently editing this dataset. Come back later once they are done.",
      };
    } else if (disabled) {
      return {
        popOverTitle: "Changes detected",
        popOverDescription: "Please sync changes to start editing.",
      };
    } else if (shouldLockNode) {
      return {
        popOverTitle: "Node is running",
        popOverDescription: "Please wait for execution to finish.",
      };
    }
    return {
      popOverTitle: "",
      popOverDescription: "",
    };
  }, [isEDAFromWorkflow, isWFEditAllowed, currentActiveUser, disabled]);

  const onTransform = async () => {
    try {
      const res = await startEditing({
        edaId: edaId!,
        analysisId: analysisId!,
      });
      const response = await refreshAPI({
        edaId: edaId!,
        analysisId: analysisId!,
      }).unwrap();

      if (res.error) throw res.error;

      dispatch(
        setEdaMetaData({
          accessMode: ACCESS_MODE.READ_WRITE,
          userHasWriteAccess: true,
          activeUser: response?.response?.data?.activeUser || null,
        })
      );
      // dispatch(triggerFetch(FETCH_TYPE.STEPS));
    } catch (e: any) {
      if (e?.status === 403) {
        toast({
          title: "User does not have write access permission to this analysis",
          status: "error",
        });
      }
      if (e?.status === 400) {
        toast({
          title: "User does not have write access to this analysis",
          status: "error",
        });
      }
      console.error(e);
    }
  };
  return (
    <Popover closeOnBlur={true} isLazy isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          sx={{
            ".chakra-button__icon": {
              marginInlineStart: "2px !important",
            },
          }}
          colorScheme="secondary"
          isDisabled={isTranformDisabled || disabled}
          isLoading={isLoading || loadingStatus}
          onClick={onTransform}
          onMouseLeave={() => {
            onClose();
          }}
          onMouseOver={() => {
            if (isTranformDisabled || disabled) onOpen();
          }}
          rightIcon={<Icon as={MdsTransformRound} size="sm" />}
          size="sm"
          variant="solid"
        >
          Edit Transformations
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          className="!outline-0"
          sx={{
            ":focus-visible": {
              outline: "none",
            },
            width: "240px",
          }}
        >
          <PopoverArrow />
          <Flex className="p-3 flex-col gap-y-1.5">
            <Flex>
              <Flex className="items-center gap-x-1">
                <PopoverIcon />
                <Text className="text-sm text-gray-900 font-medium leading-[120%]">
                  {popOverTitle}
                </Text>
              </Flex>
            </Flex>
            <Text className="text-[13px] leading-[120%]">
              {popOverDescription}
            </Text>
          </Flex>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

const PopoverIcon = () => {
  return (
    <Flex className="items-center p-0.5 bg-yellow-50 rounded-full border border-yellow-100">
      <Icon as={ScheduleFilled} color="yellow.500" size="xs" />
    </Flex>
  );
};
