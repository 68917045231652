import { Flex, Icon, Skeleton } from "@chakra-ui/react";
import { useMemo } from "react";
import { MdsErrorRound } from "react-icons-with-materialsymbols/mds";

import { NodeParameter } from "../../types";
import { NODE_TYPES, PARAMETER_TYPE } from "../../utils/constants";

import { DateConfig } from "./config-forms/date-config";
import MergeConfig from "./config-forms/merge-config";
import { MultiSelectConfig } from "./config-forms/multiselect-config";
import { RangeConfig } from "./config-forms/range-config";
import { SourceNodeConfig } from "./config-forms/source-config";
import { TextConfig } from "./config-forms/text-config";
import { useConfigPanel } from "./config-panel-context";
import { validateParameter } from "./config-utils";

export const ConfigElements = () => {
  const {
    parameters,
    setParameters,
    currentNodeData: node,
    loadingConfig,
    error,
  } = useConfigPanel();

  const isMergeNode =
    (node?.name.includes("Merge") || node?.name.includes("Join Tables")) ??
    false;
  const isSourceNode = node?.nodeType === NODE_TYPES.SOURCE_NODE;

  const ComponentMap = useMemo(() => {
    const updateParameter = (paramId: string, value: any) => {
      const newParams = parameters.map((p) => {
        if (p.parameterId === paramId) {
          const { errors } = validateParameter({ ...p, value });
          return {
            ...p,
            value: value,
            errors: errors,
          };
        }
        return p;
      });
      setParameters(newParams);
    };

    return (param: NodeParameter) => {
      switch (param.dataType) {
        case PARAMETER_TYPE.RANGE:
          return (
            <RangeConfig param={param} updateParameter={updateParameter} />
          );
        case PARAMETER_TYPE.DROPDOWN:
          return (
            <MultiSelectConfig
              param={param}
              updateParameter={updateParameter}
            />
          );
        case PARAMETER_TYPE.TEXT:
          return <TextConfig param={param} updateParameter={updateParameter} />;
        case PARAMETER_TYPE.DATE:
          return <DateConfig param={param} updateParameter={updateParameter} />;
        default:
          return <TextConfig param={param} updateParameter={updateParameter} />;
      }
    };
  }, [parameters, setParameters]);

  if (loadingConfig) return <Skeleton className="w-full h-20" />;
  if (!node) return null;
  if (error)
    return (
      <Flex className="w-full p-2 items-center gap-2 justify-center rounded border bg-red-50 text-red-600">
        <Icon className="stroke-[22]" as={MdsErrorRound} />
        {error}
      </Flex>
    );
  return (
    <>
      {isMergeNode && <MergeConfig />}
      {isSourceNode && (
        <SourceNodeConfig
          parameters={parameters}
          setParameters={setParameters}
        />
      )}
      {parameters.map((param, idx) => {
        if (param.isHidden) return null;
        return (
          <Flex className="w-full" key={idx} direction="column">
            {ComponentMap(param)}
          </Flex>
        );
      })}
    </>
  );
};
