import clsx from "clsx";
import React, {
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ReactFlow, {
  addEdge,
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  ReactFlowInstance,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import { v4 as uuidv4 } from "uuid";

import { ToastType, useShowToast } from "@/components/toast";
import { LLMPrompt } from "@/features/llm-prompt/components/llm-prompt";
import { useFlowAutoSave } from "@/features/workflow-studio/hooks";
import {
  setContextMenu,
  triggerAutoSave,
  workflowContextMenu,
} from "@/features/workflow-studio/redux";
import { useAppSelector } from "@/reduxHooks.ts";

import { useGetNodesListQuery } from "../../api";
import { getEditingAllowed } from "../../redux/workflow-slice";
import { NODE_STATUS } from "../../utils/constants";
import {
  isConnectionAllowed,
  validateForCycles,
} from "../../utils/validations";
import BottomBar from "../bottom-bar";
import { NodeConfigPanel } from "../config-panel";
import {
  CustomEdge,
  CustomMarkers,
  DataNode,
  NodeInfoPreview,
} from "../custom-node";
import FlowNode from "../custom-node/flow-node";
import MultiSelectNodeToolbar from "../custom-node/multi-select-node-toolbar";
import { DataPreviewPanel } from "../data-preview";
import { DataTransformationPanel } from "../data-transformation-panel";
import { FlowStore } from "../flow-store";
import { LogsPanel } from "../logs-panel";
import { CTXMenuProps } from "../node-menu";
import ContextMenu from "../node-menu/node-menu";
import { StatusBar } from "../status-bar";

import "reactflow/dist/style.css";

export const nodeTypes = { "custom-node": DataNode, "group-node": FlowNode };
export const edgeTypes = { "custom-edge": CustomEdge };

const defaultEdgeOptions = {
  type: "custom-edge",
  zIndex: 1001,
};

interface ReactFlowInstanceContextProps {
  setNodesFn: React.Dispatch<
    React.SetStateAction<Node<any, string | undefined>[]>
  >;
  setEdgesFn: React.Dispatch<React.SetStateAction<Edge<any>[]>>;
  editorInstance: ReactFlowInstance | null;
  nodes: Node<any, string | undefined>[];
  edges: Edge<any>[];
}

export const ReactFlowInstanceContext =
  createContext<ReactFlowInstanceContextProps | null>({
    nodes: [],
    edges: [],
    setNodesFn: () => {},
    setEdgesFn: () => {},
    editorInstance: null,
  });

const FlowEditor = ({
  initialEdges,
  initialNodes,
}: {
  initialEdges: Edge[];
  initialNodes: Node[];
}) => {
  // const { cancelAutoSave } = useFlowAutoSave();

  const toast = useShowToast(undefined, undefined, true);
  const params = useParams();
  const dispatch = useDispatch();

  const { data: nodeList } = useGetNodesListQuery({
    analysisId: params.analysisId as string,
  });

  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const { project, getNode, fitView, toObject } = useReactFlow();

  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  useFlowAutoSave(toObject, setNodes);

  const contextMenu = useAppSelector(workflowContextMenu);
  const ref = useRef<HTMLDivElement>(null);

  const isEditingAllowed = useAppSelector(getEditingAllowed);

  const setMenu = useCallback(
    (menu: CTXMenuProps | null) => {
      dispatch(setContextMenu(menu));
    },
    [dispatch]
  );

  // useEffect(() => {
  //   if (!reactFlowInstance) return;

  //   dispatch(setFlowInstance(reactFlowInstance));

  //   if (workflowRunStatus === NODE_STATUS.RUNNING) {
  //     cancelAutoSave();
  //   }
  //   return () => {
  //     dispatch(setFlowInstance(null));
  //     cancelAutoSave();
  //   };
  // }, [workflowRunStatus, params.editorId, reactFlowInstance]);

  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);
    setTimeout(() => {
      fitView({ duration: 500, maxZoom: 1 });
    }, 300);
  }, [initialEdges, initialNodes, setEdges, setNodes, fitView]);

  const isValidConnection = useCallback(
    (connection: Connection) => {
      // Note: might have to use getNodes,getEdges from Reactflow, check documentation
      const cycleValidationError = validateForCycles(connection, nodes, edges);
      if (cycleValidationError !== "") {
        if (!toast.isActive("flowCycleError")) {
          toast({
            id: "flowCycleError",
            title: "Cycle Detected",
            description: cycleValidationError,
            status: ToastType.Warning,
          });
        }
        return false;
      }
      return true;
    },
    [toast, nodes, edges]
  );

  const autoSave = () => {
    setTimeout(() => {
      dispatch(triggerAutoSave());
    }, 300);
  };

  const onConnect = useCallback(
    (connection: Connection) => {
      if (!isEditingAllowed) return;

      // takeSnapshot();
      if (connection.source === null || connection.target === null) return;
      const validationRes = isConnectionAllowed(
        getNode(connection.source)!,
        getNode(connection.target)!,
        edges
      );
      if (!validationRes.isConnectionAllowed) {
        if (
          !toast.isActive(
            connection.source + connection.target + "connectionError"
          )
        ) {
          toast({
            id: connection.source + connection.target + "connectionError",
            status: ToastType.Error,
            title: validationRes.message ?? "Invalid Connection",
          });
        }
        return;
      }
      setEdges((eds) =>
        addEdge(
          {
            ...connection,
            data: validationRes.data,
          },
          eds
        )
      );
      autoSave();
    },
    [edges, setEdges, getNode, toast, isEditingAllowed]
  );

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onNodesDelete = (deletedNodes: Node[]) => {
    if (!isEditingAllowed) return;
    // if there are edges with source or target in deleted nodes, remove them
    const deletedEdges = edges.filter((edge) =>
      deletedNodes.some(
        (node) => node.id === edge.source || node.id === edge.target
      )
    );
    setEdges((eds) => eds.filter((edge) => !deletedEdges.includes(edge)));

    autoSave();
  };

  const onNodeContextMenu = useCallback(
    (event: React.MouseEvent, node: Node) => {
      // Prevent native context menu from showing
      event.preventDefault();
      // Calculate position of the context menu. We want to make sure it
      // doesn't get positioned off-screen.
      const pane = reactFlowWrapper.current?.getBoundingClientRect();
      if (pane) {
        const overflowX = event.clientX - 220 > pane.width - 250;
        const overflowY = event.clientY + 250 > pane.height;
        setMenu({
          id: node.id,
          left: overflowX ? event.clientX - 410 : event.clientX - 220,
          right: overflowX ? undefined : undefined,
          top: overflowY ? undefined : event.clientY - 60,
          bottom: overflowY ? pane.height - event.clientY - 100 : undefined,
        });
      }
    },
    [setMenu, reactFlowWrapper]
  );
  const onPaneClick = useCallback(() => {
    setMenu(null);
  }, [setMenu]);

  const onDrop = (event: React.DragEvent) => {
    event.preventDefault();
    if (!isEditingAllowed) {
      toast({
        title: "Cannot add node while workflow is running",
        status: ToastType.Error,
      });
      return;
    }

    if (reactFlowWrapper.current) {
      const wrapperBounds = reactFlowWrapper.current.getBoundingClientRect();
      const nodeVersionId = event.dataTransfer.getData("application/nodeIdx");
      const nodeType = event.dataTransfer.getData("application/nodeType");
      const nodeGroup = event.dataTransfer.getData("application/nodeGroup");
      const nodeIndex = event.dataTransfer.getData("application/nodeIndex");

      // find node with nodeVersionId in nodeList[nodeGroup]
      // TODO : currently using index to find node, change to nodeUsageInstanceId later
      // const node = nodeList![nodeGroup].find(
      //   (item: NodeType) => item.nodeVersionId === nodeVersionId
      // );

      const node = nodeList![nodeGroup][Number(nodeIndex)];

      // check if the dropped element is valid
      if (typeof nodeType === "undefined" || !node) {
        return;
      }
      const position = project({
        x: event.clientX - wrapperBounds.x - 20,
        y: event.clientY - wrapperBounds.top - 20,
      });
      const newNode = {
        id: uuidv4(),
        data: { ...node, status: NODE_STATUS.DEFAULT, name: node.displayName },
        type: "custom-node",
        position,
      };
      setNodes((nds) => nds.concat(newNode));
      autoSave();
    }
  };

  const handleEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      // Allow system updates but block user interactions when editing is disabled
      if (!isEditingAllowed) {
        // Only allow 'reset' type changes (from setEdges)
        const allowedChanges = changes.filter(
          (change) => change.type === "reset"
        );
        if (allowedChanges.length > 0) {
          onEdgesChange(allowedChanges);
        }
        return;
      }
      onEdgesChange(changes);
    },
    [isEditingAllowed, onEdgesChange]
  );

  const handleNodesChange = useCallback(
    (changes: NodeChange[]) => {
      // Allow system updates but block user interactions when editing is disabled
      if (!isEditingAllowed) {
        const allowedChanges = changes.filter(
          (change) =>
            // Allow 'reset' type changes (from setNodes)
            change.type === "reset" ||
            // Allow position changes from system updates
            (change.type === "position" && change.dragging === false)
        );
        if (allowedChanges.length > 0) {
          onNodesChange(allowedChanges);
        }
        return;
      }
      onNodesChange(changes);
    },
    [isEditingAllowed, onNodesChange]
  );

  const contextValue = useMemo(
    () => ({
      setNodesFn: setNodes,
      setEdgesFn: setEdges,
      editorInstance: reactFlowInstance || null,
      nodes,
      edges,
    }),
    [setNodes, setEdges, reactFlowInstance, nodes, edges]
  );

  return (
    <ReactFlowInstanceContext.Provider value={contextValue}>
      <div className="h-full w-full relative" ref={reactFlowWrapper}>
        <ReactFlow
          ref={ref}
          onInit={setReactFlowInstance}
          className={clsx(
            "h-full grow select-none",
            isEditingAllowed ? "bg-gray-100" : "bg-white"
          )}
          nodes={nodes}
          edges={edges}
          onNodesDelete={onNodesDelete}
          onNodesChange={handleNodesChange}
          onEdgesChange={handleEdgesChange}
          onConnect={onConnect}
          onDrop={onDrop}
          onDragOver={onDragOver}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          isValidConnection={isValidConnection}
          minZoom={0.3}
          maxZoom={2}
          zoomOnDoubleClick={false}
          elevateEdgesOnSelect={true}
          proOptions={{ hideAttribution: true }}
          defaultEdgeOptions={defaultEdgeOptions}
          edgesUpdatable={isEditingAllowed}
          edgesFocusable={isEditingAllowed}
          nodesDraggable={isEditingAllowed}
          nodesConnectable={isEditingAllowed}
          nodesFocusable={isEditingAllowed}
          onPaneClick={onPaneClick}
          onNodeContextMenu={onNodeContextMenu}
          multiSelectionKeyCode={isEditingAllowed ? "Shift" : null}
          selectionKeyCode={isEditingAllowed ? "Shift" : null}
        >
          <Background id="bg-main" variant={BackgroundVariant.Dots} />
          <Controls showInteractive={false} />
        </ReactFlow>
        {contextMenu && (
          <ContextMenu
            setMenu={setMenu}
            onClick={onPaneClick}
            {...contextMenu}
          />
        )}
        {/* ------------------------------- Node Elements ------------------------------- */}
        <MultiSelectNodeToolbar />
        <CustomMarkers />

        {/* ------------------------------- Bottom Bar Elements ------------------------------- */}
        <BottomBar />
        <FlowStore />
        <LLMPrompt />

        {/* ------------------------------- Panel Elements ------------------------------- */}

        <NodeInfoPreview />
        <NodeConfigPanel />
        {/* <DataPreview /> */}
        <DataPreviewPanel />
        <DataTransformationPanel />
        <LogsPanel />
        {/* <DataDownloadPanel /> */}

        {/* ------------------------------- Workflow Elements ------------------------------- */}

        {/* NOTE: Need to explicitly pass setNodes to StatusBar to update the nodes, becase the useReactFlow() hook is not working in StatusBar */}
        <StatusBar nodes={nodes} edges={edges} />
      </div>
    </ReactFlowInstanceContext.Provider>
  );
};

export default memo(FlowEditor);
