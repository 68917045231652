import { CircularProgress, Flex, Text } from "@chakra-ui/react";

const Loader = ({
  title = "Loading",
  color = "gray.700",
  size = 30,
}: {
  title?: string;
  color?: string;
  size?: number;
}) => {
  return (
    <Flex className="justify-center h-full gap-2 w-full items-center">
      <CircularProgress color={color} isIndeterminate size={size} />
      <Text className="ml-2">{title}</Text>
    </Flex>
  );
};

export default Loader;
