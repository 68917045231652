import { isEmpty, isEqual, min } from "lodash";
import { Connection, Edge, Node, getIncomers, getOutgoers } from "reactflow";

import {
  NodeErrorValidationSchema,
  NodeIO,
  NodeParameter,
  NodeRelations,
  NodeType,
} from "../types";
import { WorkflowNodeRelationsSchema } from "../types/workflow-types";

import { RELATION_TYPE } from "./constants";

export const validateNodeforGrouping = (nodes: Node[]): string => {
  // check if node type is not "SUPER_NODE"

  const isFlowNode = nodes.some((node) => node.type === "group-node");
  if (isFlowNode) {
    return "Cannot group a flow node";
  }

  const hasParentNode = nodes.some((node) => node.parentNode);
  if (hasParentNode) {
    return "Cannot group a node with a parent node";
  }

  // const isSuperNode = nodes.some((node) => node.data.nodeType === "SUPER_NODE");
  // if (isSuperNode) {
  //   return "Cannot group a super node";
  // }
  return "";
};

export const validateForCycles = (
  connection: Connection,
  nodes: Node[],
  edges: Edge[]
): string => {
  const target = nodes.find((node: Node) => node.id === connection.target);

  const hasCycle = (node: Node, visited = new Set()) => {
    if (visited.has(node.id)) return false;
    visited.add(node.id);
    for (const outgoer of getOutgoers(node, nodes, edges)) {
      if (outgoer.id === connection.source) return true;
      if (hasCycle(outgoer, visited)) return true;
    }
  };

  if (target!.id === connection.source) return "Cannot connect to self";
  if (hasCycle(target!)) return "Flow Cannot contain cycles";

  return "";
};

export function isConnectionAllowed(
  sourceNode: Node,
  targetNode: Node,
  edgesArray: Edge[]
): {
  isConnectionAllowed: boolean;
  data: WorkflowNodeRelationsSchema;
  message?: string;
} {
  // const isConnectionPossible = sourceNode.data.outputs.filter(
  //   (sourceOutputNode: NodeIO) => {
  //     const availableConnections = targetNode.data.inputs.some(
  //       (targetInputNode: NodeIO) =>
  //         targetInputNode.connectorType === sourceOutputNode.connectorType
  //     );
  //     return availableConnections;
  //   }
  // );
  const matchingConnectorType = sourceNode.data.outputs.filter(
    (output: NodeIO) =>
      targetNode.data.inputs.some(
        (input: NodeIO) => input.connectorType === output.connectorType
      )
  );
  if (!matchingConnectorType) {
    return {
      isConnectionAllowed: false,
      data: {} as WorkflowNodeRelationsSchema,
      message: `Invalid Connection. Connector type mismatch`,
    };
  }

  const availableInputConnections = targetNode.data.inputs.filter(
    (input: NodeIO) => {
      const connectedEdges = edgesArray.filter(
        (edge) =>
          edge.data.targetNodeIoId === input.ioDetailId &&
          edge.target === targetNode.id
      );
      const connectedCount = connectedEdges.length;
      return connectedCount < input.maxConnections;
    }
  );
  // Check if the source node has available output connections
  const availableOutputConnections = sourceNode.data.outputs.filter(
    (output: NodeIO) => {
      const connectedEdges = edgesArray.filter(
        (edge) =>
          edge.data.sourceNodeIoId === output.ioDetailId &&
          edge.source === sourceNode.id
      );
      const connectedCount = connectedEdges.length;
      return connectedCount < output.maxConnections;
    }
  );
  // Check for matching connector types between source and target nodes
  // Iterate through all possible combinations of available input and output connections

  if (availableInputConnections.length === 0) {
    return {
      isConnectionAllowed: false,
      data: {} as WorkflowNodeRelationsSchema,
      message: `Max connections reached for ${targetNode.data.name}`,
    };
  }
  if (availableOutputConnections.length === 0) {
    return {
      isConnectionAllowed: false,
      data: {} as WorkflowNodeRelationsSchema,
      message: `Max connections reached for ${sourceNode.data.name}`,
    };
  }

  for (const inputConnection of availableInputConnections) {
    for (const outputConnection of availableOutputConnections) {
      // Check if the connection already exists in edges array
      const connectionExists = edgesArray.some(
        (edge) =>
          edge.source === sourceNode.id &&
          edge.data.sourceNodeIoId === outputConnection.ioDetailId &&
          edge.target === targetNode.id &&
          edge.data.targetNodeIoId === inputConnection.ioDetailId
      );
      if (!connectionExists) {
        // Check if the max connections are not reached
        if (
          inputConnection.maxConnections > 0 &&
          outputConnection.maxConnections > 0
        ) {
          // Create a new edge with the matching connector types
          return {
            isConnectionAllowed: true,
            data: {
              relationType: RELATION_TYPE.ON_SUCCESS,
              sourceUiNodeId: sourceNode.id,
              targetUiNodeId: targetNode.id,
              sourceNodeIoId: outputConnection.ioDetailId,
              targetNodeIoId: inputConnection.ioDetailId,
            },
            message: `Connected Successfully`,
          };
        }
      }
    }
  }
  // No valid connection found
  return {
    isConnectionAllowed: false,
    data: {} as WorkflowNodeRelationsSchema,
    message: `Invalid Connection. Connector type mismatch`,
  };
}

/**
 * 1. Check if connection is possible between source and target node by comparing the connectorType and connectorValue
 * 2. Check if the count of existing connections in edges array for the target node / source node is less than maxConnections of the connector
 * 3. If yes, return true
 * 4. If no, return false
 */

//   const connection = sourceNode.data.outputs.flatMap(
//     (sourceOutputNode: NodeIO) => {
//       const availableConnections = targetNode.data.inputs.filter(
//         (targetInputNode: NodeIO) =>
//           targetInputNode.connectorType === sourceOutputNode.connectorType
//       );

//       if (availableConnections.length > 0) {
//         const avaiableIOIds = availableConnections.map(
//           (availableConnection: NodeIO) => availableConnection.ioDetailId
//         );

//         // filter out io ids which are already connected using edges array
//         const filteredAvailableConnections = availableConnections.filter(
//           (availableConnection: NodeIO) => {
//             const matchingEdges = edgesArray.filter(
//               (edge) =>
//                 edge.data.sourceIoDetailsId === sourceOutputNode.ioDetailId &&
//                 edge.data.targetIoDetailsId === availableConnection.ioDetailId
//             );
//             return matchingEdges.length === 0;
//           }
//         );

//         // Combine sourceOutput and matchingInput
//         return {
//           sourceIOId: sourceOutput.ioDetailId,
//           targetIOId: matchingInput.ioDetailId,
//           sourceIOName: sourceOutput.name,
//           targetIOName: matchingInput.name,
//           sourceMinConnections: sourceOutput.minConnections,
//           targetMinConnections: matchingInput.minConnections,
//           sourceMaxConnections: sourceOutput.maxConnections,
//           targetMaxConnections: matchingInput.maxConnections,
//         };
//       }
//       // No match found
//       return null;
//     }
//   );
//   if (connection) {
//     // loop through the edges array and count the number of connections containing the 'connection' in its data property
//     const countOfExistingOutputConnections = edgesArray.filter(
//       (edge) =>
//         edge.data.sourceIoDetailsId === connection.sourceIOId &&
//         edge.source === sourceNode.id
//     ).length;
//     const countOfExistingInputConnections = edgesArray.filter(
//       (edge) =>
//         edge.data.targetIoDetailsId === connection.targetIOId &&
//         edge.target === targetNode.id
//     ).length;

//     // Check if the count is less than maxConnections
//     if (countOfExistingInputConnections < connection.targetMaxConnections) {
//       if (countOfExistingOutputConnections < connection.sourceMaxConnections) {
//         return {
//           isConnectionAllowed: true,
//           data: {
//             relationType: RELATION_TYPE.ON_SUCCESS,
//             sourceUiNodeId: sourceNode.id,
//             targetUiNodeId: targetNode.id,
//             sourceNodeIoId: connection.sourceIOId,
//             targetNodeIoId: connection.targetIOId,
//           },
//           message: `Connected Successfully`,
//         };
//       } else
//         return {
//           isConnectionAllowed: false,
//           data: {} as WorkflowNodeRelationsSchema,
//           message: `Max connections of type ${connection.sourceIOName}, reached for Source :\n ${sourceNode.data.displayName}`,
//         };
//     } else
//       return {
//         isConnectionAllowed: false,
//         data: {} as WorkflowNodeRelationsSchema,
//         message: `Max connections of type ${connection.targetIOName}, reached for ${targetNode.data.displayName}`,
//       };
//   }
//   // No valid connection found
//   return {
//     isConnectionAllowed: false,
//     data: {} as WorkflowNodeRelationsSchema,
//     message: `Invalid Connection. Connector type mismatch`,
//   };
// }

export const validateNodeConfig = (
  node: Node,
  nodes: Node[] = [],
  edgesArray: Edge[] = []
): NodeErrorValidationSchema => {
  if (node.type === "group-node") {
    return {
      isValid: true,
      configErrors: [],
      connectionErrors: [],
    };
  }
  const configErrors: string[] = [];
  const connectionErrors: string[] = [];
  const errors = {
    isRogueNode: false,
    inputsRequired: 0,
    inputsReceived: 0,
    outputsRequired: 0,
    outputsReceived: 0,
  };
  //  if node.data.parameters is not empty, check if all the mandatory parameters have a value attribute and if the value is not empty
  if (node.data.parameters.length > 0) {
    node.data.parameters.forEach((parameter: NodeParameter) => {
      let value = parameter.value;
      try {
        value = JSON.parse(parameter.value as string);
      } catch (error) {
        // if not valid json, keep the value as it is
      }
      const isParameterEmpty =
        parameter.isMandatory &&
        isEmpty(value) &&
        !parameter.defaultValue;

      if (isParameterEmpty) {
        configErrors.push(`Mandatory parameter ${parameter.name} is missing`);
      }
    });
  }

  // if node.data.inputs is not empty, check if all the mandatory inputs have a connection in the edges array
  if (node.data.inputs.length > 0) {
    const incomerCount = getIncomers(node, nodes, edgesArray).length;
    // console.log(getIncomers(node, nodes, edgesArray));
    const totalInputsCount = node.data.inputs.reduce(
      (count: number, input: NodeIO) => count + input.minConnections,
      0
    );
    errors.inputsRequired = totalInputsCount;
    errors.inputsReceived = incomerCount;
    if (incomerCount < totalInputsCount) {
      connectionErrors.push(
        `Requires atleast ${totalInputsCount} input(s). Got ${incomerCount}`
      );
    }
    // node.data.inputs.forEach((input: NodeIO) => {
    //   if (input.isMandatory) {
    //     const minIPConnections = input.minConnections;
    //     const matchingIPEdges = edgesArray.filter(
    //       (edge) =>
    //         edge.data.targetUiNodeId === node.id &&
    //         edge.data.sourceNodeIoId === input.ioDetailId
    //     );
    //     console.log(node.data.displayName, matchingIPEdges);
    //     if (matchingIPEdges.length < minIPConnections) {
    //       connectionErrors.push(`Input : ${input.name} is not connected`);
    //     }
    //   }
    // });
  }

  // if node.data.outputs is not empty, check if all the mandatory outputs have a connection in the edges array
  if (node.data.outputs.length > 0) {
    node.data.outputs.forEach((output: NodeIO) => {
      const matchingOPEdges = edgesArray.filter(
        (edge) => edge.source === node.id
      );
      errors.outputsReceived = matchingOPEdges.length;
      if (output.isMandatory) {
        const minOPConnections = output.minConnections;
        errors.outputsRequired = minOPConnections;
        if (matchingOPEdges.length < minOPConnections) {
          connectionErrors.push(
            `Requires atleast ${minOPConnections} output(s). Got ${matchingOPEdges.length}`
            // `Mandatory output ${output.name} is not connected`
          );
        }
      }
    });
  }
  errors.isRogueNode =
    errors.inputsReceived === 0 && errors.outputsReceived === 0;

  return {
    isValid: configErrors.length === 0 && connectionErrors.length === 0,
    configErrors,
    connectionErrors,
    errors,
  };
};

export const validateNodesInGroup = (nodes: Node[], edges: Edge[]): { isValid: boolean; errors: string[] } => {
  const errors: string[] = [];

  for (const node of nodes) {
    if (node.type === "group-node") continue;

    const nodeValidation = validateNodeConfig(node, nodes, edges);
    if (nodeValidation.errors?.isRogueNode) {
      errors.push(`Node "${node.data.displayName}" is not connected to any other node`);
    }
  }

  return {
    isValid: errors.length === 0,
    errors
  };
};
