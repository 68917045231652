import { toUpper } from "lodash";
import React, { useMemo } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import {
  MdsContentCopyRound,
  MdsDeleteRound,
  MdsPlaylistAddRound,
  MdsPlaylistRemoveRound,
} from "react-icons-with-materialsymbols/mds";
import { NodeToolbar, useReactFlow } from "reactflow";

import { Button } from "@/design/components/button";
import { useAppDispatch } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import { EXECUTION_TYPE } from "@/utils/enums";

import { showPanel } from "../..";
import { useCreateCopyNode } from "../../hooks/useCreateNodeCopy";
import useManageOutput from "../../hooks/useManageOutput";
import { useRunNode } from "../../hooks/useRunNode";
import { useWorkflowActions } from "../../hooks/useWorkflowActions";
import { triggerAutoSave } from "../../redux";
import { NodeType } from "../../types";
import {
  NODE_RUN_OPTIONS_ICONS,
  NODE_STATUS,
  WORKFLOW_PANELS,
} from "../../utils/constants";
import { validateNodeConfig } from "../../utils/validations";

type DataNodeToolbarProps = {
  onDelete: () => void;
  id: string;
  nodeStatus: NODE_STATUS | undefined;
  isLocked?: boolean;
};

const getNodeRunOptionFromStatus = (status: NODE_STATUS) => {
  switch (status) {
    case NODE_STATUS.DEFAULT:
      return "DEFAULT";
    case NODE_STATUS.PENDING:
      return "PENDING";
    case NODE_STATUS.RUNNING:
      return "TERMINATE";
    case NODE_STATUS.SUCCESS:
    case NODE_STATUS.FAILED:
    case NODE_STATUS.SKIPPED:
      return "RERUN";
    case NODE_STATUS.CANCELLED:
      return "CANCELLED";
    case NODE_STATUS.CANCELLING:
      return "RUNNING";
    default:
      return "DEFAULT";
  }
};

export const DataNodeToolbar = ({
  onDelete,
  id,
  nodeStatus = undefined,
  isLocked = false,
}: DataNodeToolbarProps) => {
  const dispatch = useAppDispatch();
  const { getNode, getNodes, getEdges } = useReactFlow();
  const { createNodeCopy } = useCreateCopyNode();
  const { isUnMarking, removeOutput, openOutputModal } = useManageOutput();
  const { getWFNodeStatus } = useWorkflowActions();
  const { runFromNode, isLoading, terminateNodeRunFromNode, isTerminating } =
    useRunNode();

  const currentNode = getNode(id);
  const hasParent = currentNode?.parentNode;

  const isSourceNode = useMemo(
    () =>
      (currentNode?.data as NodeType).nodeType
        .toUpperCase()
        .includes("SOURCE".toUpperCase()),
    [currentNode]
  );
  const isOutput = useMemo(
    () => currentNode?.data.isOutput,
    [currentNode?.data]
  );

  const currentNodeStatus = getWFNodeStatus(id);

  const { isNodeRunning, isNodeLocked } = useMemo(
    () => ({
      isNodeRunning:
        nodeStatus === NODE_STATUS.RUNNING ||
        currentNodeStatus?.status === NODE_STATUS.RUNNING,
      isNodeLocked: isLocked || currentNodeStatus?.isLocked,
    }),
    [nodeStatus, isLocked, currentNodeStatus]
  );

  const nodeValidation = useMemo(() => {
    if (!currentNode) return null;
    return validateNodeConfig(currentNode, getNodes(), getEdges());
  }, [currentNode, getNodes, getEdges]);

  const hasConfigErrors = useMemo(() => {
    return nodeValidation ? !nodeValidation.isValid : false;
  }, [nodeValidation]);

  const NodeRunIcon = useMemo(() => {
    if (hasConfigErrors) {
      return NODE_RUN_OPTIONS_ICONS["INVALID"];
    }
    if (isLoading) {
      return NODE_RUN_OPTIONS_ICONS["RUNNING"];
    }
    const status = getNodeRunOptionFromStatus(
      nodeStatus ??
        (currentNode?.data as NodeType).nodeStatus ??
        NODE_STATUS.DEFAULT
    );

    return NODE_RUN_OPTIONS_ICONS[status];
  }, [currentNode?.data, nodeStatus]);

  const createOutput = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    openOutputModal({ id });
  };

  const clearOutput = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    removeOutput({ id });
  };

  const showDataPreviewPanel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    dispatch(
      showPanel({ panel: WORKFLOW_PANELS.DataPreviewPanel, nodeId: id })
    );
  };

  const duplicateNode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (currentNode) {
      createNodeCopy(currentNode);
      dispatch(triggerAutoSave());
    }
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isNodeRunning || isNodeLocked) {
      return;
    }
    e.stopPropagation();
    dispatch(
      openModal({
        modalType: ModalTypes.DELETE_NODE,
        modalProps: {
          nodeData: currentNode?.data as NodeType,
          onConfirmDelete: onDelete,
        },
      })
    );
  };

  const onRunClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (isNodeRunning) {
      await terminateNodeRunFromNode(
        currentNode?.data?.workflowNodeId as string
      );
      return;
    } else {
      try {
        await runFromNode(id, EXECUTION_TYPE.SINGLE_NODE_EXECUTION);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="absolute -z-10 -top-8 left-1/2 -translate-x-1/2 w-full px-3 h-full">
      <NodeToolbar
        isVisible={true}
        className="flex gap-0 w-max rounded bg-zinc-700 p-1"
      >
        {!isSourceNode && (
          <Button
            variant={"link"}
            colorScheme="black"
            className="!p-1 rounded !text-white hover:bg-zinc-600"
            title={
              isNodeRunning
                ? "Terminate node run"
                : hasConfigErrors
                ? "Node has configuration errors"
                : "Execute node"
            }
            onClick={onRunClick}
            isDisabled={!isNodeRunning && (isNodeLocked || hasConfigErrors)}
          >
            {NodeRunIcon}
          </Button>
        )}
        <Button
          variant={"link"}
          colorScheme="black"
          className="!p-2 rounded !text-white hover:bg-zinc-600"
          onClick={showDataPreviewPanel}
          title="Node Data Preview"
        >
          <MdOutlineRemoveRedEye size={20} />
        </Button>
        {!hasParent && (
          <Button
            variant={"link"}
            colorScheme="black"
            className="!p-2 rounded !text-white hover:bg-zinc-600"
            title="Duplicate node"
            onClick={duplicateNode}
          >
            <MdsContentCopyRound className="stroke-[22]" size={20} />
          </Button>
        )}
        {!isSourceNode &&
          (isOutput ? (
            <Button
              variant={"link"}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={clearOutput}
              colorScheme="black"
              isDisabled={isUnMarking}
              className="!p-2 rounded !text-white hover:bg-zinc-600"
              title="Remove Output Dataset"
            >
              <MdsPlaylistRemoveRound className="stroke-[24]" size={24} />
            </Button>
          ) : (
            <Button
              variant={"link"}
              onClick={createOutput}
              colorScheme="black"
              isDisabled={isUnMarking}
              className="!p-2 rounded !text-white hover:bg-zinc-600"
              title="Create Output Dataset"
            >
              <MdsPlaylistAddRound className="stroke-[24]" size={24} />
            </Button>
          ))}
        {!hasParent && (
          <Button
            variant={"link"}
            colorScheme="black"
            className="!p-2 rounded !text-white hover:bg-zinc-600"
            onClick={handleDelete}
            isDisabled={isNodeRunning || isNodeLocked}
            title="Delete node"
          >
            <MdsDeleteRound className="stroke-[22]" size={22} />
          </Button>
        )}
      </NodeToolbar>
    </div>
  );
};

export default DataNodeToolbar;
