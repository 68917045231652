import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import React from "react";

import { ToastType, useShowToast } from "@/components/toast";
import DeleteAccountMessage from "@/features/user-settings/components/account-detials/delete-acc-message";
import useAuth from "@/hooks/useAuth";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";
import { STATUS } from "@/utils/enums";

import { useRemoveUserFromPlatformMutation } from "../../api";

const DeleteAccountBody = () => {
  const [error, setError] = React.useState<string>();
  const { msalInstance } = useAuth();
  const account = msalInstance.getActiveAccount();
  const { idTokenClaims } = account!;
  const azureOid = idTokenClaims?.oid;
  const userEmail = idTokenClaims?.emails?.[0];

  const toast = useShowToast(undefined, undefined, true);
  const { modalProps } = useAppSelector((s) => s.rootReducer.modals);
  const dispatch = useAppDispatch();

  const [deleteAccountApi, { isLoading }] = useRemoveUserFromPlatformMutation();

  const validateText = (text?: string | null) => {
    if (text?.toLowerCase() !== "delete my account") {
      setError("Incorrect text. Please type 'delete my account' to proceed.");
      return false;
    }
    return true;
  };

  const removeAccount = async () => {
    if (modalProps.deleteFromSelectOrg) {
      if (!userEmail || !azureOid) {
        throw new Error("User email or azure oid is missing");
      }
      return await deleteAccountApi({
        email: userEmail,
        azure_oid: azureOid,
        region: modalProps.region,
      }).unwrap();
    }
    return await deleteAccountApi({ userId: modalProps.user.userId }).unwrap();
  };

  // NOTE: Use this when OTP is implemented
  // const openOTPModal = () => {
  //   dispatch(
  //     openModal({
  //       modalType: ModalTypes.DELETE_OTP,
  //       modalProps: { user: modalProps.user },
  //     })
  //   );
  // };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const deleteText = formData.get("deletetext") as string;
    try {
      if (validateText(deleteText)) {
        setError("");
        // openOTPModal();
        const res = await removeAccount();
        if (res?.status === STATUS.SUCCESS) {
          toast({
            title: "Account Deleted",
            description: "Your account has been successfully deleted.",
            status: ToastType.Success,
          });
          dispatch(closeModal());
          setTimeout(() => {
            msalInstance.logout();
          }, 500);
        } else if (res?.status === STATUS.FAIL) {
          setError(res?.message as string);
        }
      }
    } catch (err) {
      console.log("err", err);
      setError("Something went wrong. Please try again later.");
    }
  };

  return (
    <Flex className="flex-col gap-5">
      <DeleteAccountMessage />
      <fieldset disabled={isLoading}>
        <form onSubmit={onSubmit}>
          <FormControl isInvalid={!isEmpty(error)}>
            <FormLabel htmlFor="name">
              To verify, type&nbsp;
              <span className="text-red-600">"delete my account"</span> below:
            </FormLabel>
            <Input id="deletetext" name="deletetext" />
            <FormErrorMessage>{!isEmpty(error) && error}</FormErrorMessage>
          </FormControl>
          <Button
            mt={8}
            float={"right"}
            colorScheme="customRed"
            isLoading={isLoading}
            type="submit"
          >
            Delete My Account
          </Button>
        </form>
      </fieldset>
    </Flex>
  );
};

export default DeleteAccountBody;
