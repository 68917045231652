/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box } from "@chakra-ui/react";
import {
  MdOutlineAccountTree,
  MdOutlineSwipeLeftAlt,
  MdOutlineSwipeRightAlt,
  MdOutlineCalculate,
  MdOutlineAutoGraph,
  MdOutlineHourglassEmpty,
  MdOutlineBuild,
  MdHighlightOff,
  MdCheckCircleOutline,
  MdOutlinePending,
  MdOutlineErrorOutline,
} from "react-icons/md";
import {
  MdsAccountTreeRound,
  MdsCalculateRound,
  MdsCancelRound,
  MdsDatabaseRound,
  MdsErrorRound,
  MdsHourglassRound,
  MdsPlayArrowRound,
  MdsQueryStatsRound,
  MdsRefreshRound,
} from "react-icons-with-materialsymbols/mds";

import { Loader } from "@/components/icons/loader";

export const categoryIcons: Record<string, { icon: any; theme: string }> = {
  DATA_PROCESSING: {
    theme: "blue.500",
    icon: <MdsCalculateRound className="stroke-[8] w-[28px] h-[28px]" />,
  },
  DATA_PREP: {
    theme: "blue.500",
    icon: <MdsCalculateRound className="stroke-[8] w-[28px] h-[28px]" />,
  },
  ANALYTICS: {
    theme: "orange.400",
    icon: <MdsQueryStatsRound className="stroke-[8] w-[28px] h-[28px]" />,
  },
  TRANSFORMATION: {
    theme: "orange.400",
    icon: <MdOutlineAutoGraph className="stroke-[8] w-[28px] h-[28px]" />,
  },
  FILTERING: {
    theme: "orange.400",
    icon: <MdOutlineAutoGraph className="stroke-[8] w-[28px] h-[28px]" />,
  },
  SOURCE: {
    theme: "green.500",
    icon: <MdsDatabaseRound className="stroke-[8] w-[28px] h-[28px]" />,
  },
  DESTINATION: {
    theme: "purple.500",
    icon: <MdOutlineSwipeLeftAlt className="stroke-[8] w-[28px] h-[28px]" />,
  },
  OUTPUTS: {
    theme: "purple.500",
    icon: <MdOutlineSwipeLeftAlt className="stroke-[8] w-[28px] h-[28px]" />,
  },
  FLOWS: {
    theme: "orange.400",
    icon: <MdsAccountTreeRound className="stroke-[8] w-[28px] h-[28px]" />,
  },
};

export enum WORKFLOW_PANELS {
  NodeInfoPanel = "NodeInfoPanel",
  DataPreviewPanel = "DataPreviewPanel",
  NodeConfigurationPanel = "NodeConfigurationPanel",
  FlowStorePanel = "FlowStorePanel",
  LogsPanel = "LogsPanel",
  DataTransformationPanel = "DataTransformationPanel",
  LLMConfigPanel = "LLMConfigPanel",
}

export enum NODE_STATUS {
  DEFAULT = "default",
  RUNNING = "running",
  SUCCESS = "success",
  FAILED = "failed",
  SKIPPED = "skipped",
  PENDING = "pending",
  MANUAL_INTERVENTION = "manual_intervention",
  WAITING = "waiting",
  CANCELLED = "cancelled",
  CANCELLING = "cancelling",
  PARTIALLY_RUNNING = "partially_executing",
}

export enum RELATION_TYPE {
  ON_SUCCESS = "on_success",
  ON_FAILURE = "on_failure",
  ON_COMPLETION = "on_completion",
  ON_SKIP = "on_skip",
  MAPPER = "mapper",
}

export enum PARAMETER_TYPE {
  DROPDOWN = "DROPDOWN",
  TEXT = "TEXT",
  RANGE = "RANGE",
  DATE = "DATE",
}

export const NODE_STATUS_COLORS: Record<NODE_STATUS, string> = {
  [NODE_STATUS.DEFAULT]: "gray.500",
  [NODE_STATUS.RUNNING]: "blue.500",
  [NODE_STATUS.SUCCESS]: "green.500",
  [NODE_STATUS.FAILED]: "red.500",
  [NODE_STATUS.SKIPPED]: "yellow.500",
  [NODE_STATUS.PENDING]: "gray.500",
  [NODE_STATUS.MANUAL_INTERVENTION]: "orange.500",
  [NODE_STATUS.WAITING]: "gray.500",
  [NODE_STATUS.CANCELLED]: "gray.500",
  [NODE_STATUS.CANCELLING]: "gray.500",
  [NODE_STATUS.PARTIALLY_RUNNING]: "gray.500",
};

export const NODE_STATUS_ICONS: Record<
  NODE_STATUS,
  { icon: any; theme: string }
> = {
  [NODE_STATUS.DEFAULT]: {
    theme: "gray.500",
    icon: <Box></Box>,
  },
  [NODE_STATUS.RUNNING]: {
    theme: "gray.700",
    icon: <Loader className="h-full w-full animate-spin" />,
  },
  [NODE_STATUS.SUCCESS]: {
    theme: "green.400",
    icon: <MdCheckCircleOutline className="w-full h-full" />,
  },
  [NODE_STATUS.FAILED]: {
    theme: "red.600",
    icon: <MdOutlineErrorOutline className="w-full h-full" />,
  },
  [NODE_STATUS.SKIPPED]: {
    theme: "green.400",
    icon: <MdCheckCircleOutline className="w-full h-full" />,
  },
  [NODE_STATUS.PENDING]: {
    theme: "gray.400",
    icon: <MdOutlinePending className="w-full h-full" />,
  },
  [NODE_STATUS.MANUAL_INTERVENTION]: {
    theme: "orange.400",
    icon: <MdOutlineBuild className="w-full h-full" />,
  },
  [NODE_STATUS.WAITING]: {
    theme: "yellow.400",
    icon: <MdOutlineHourglassEmpty className="w-full h-full" />,
  },
  [NODE_STATUS.CANCELLED]: {
    theme: "gray.400",
    icon: <MdHighlightOff className="w-full h-full" />,
  },
  [NODE_STATUS.CANCELLING]: {
    theme: "gray.400",
    icon: <Loader className="h-full w-full animate-spin" />,
  },
  [NODE_STATUS.PARTIALLY_RUNNING]: {
    theme: "gray.400",
    icon: <Loader className="h-full w-full animate-spin" />,
  },
};

export const NODE_RUN_OPTIONS_ICONS: Record<string, React.ReactNode> = {
  INVALID: <MdsErrorRound className="w-full h-full stroke-[24]" size={24} />,
  DEFAULT: (
    <MdsPlayArrowRound className="w-full h-full stroke-[24]" size={24} />
  ),
  PENDING: (
    <MdsPlayArrowRound className="w-full h-full stroke-[24]" size={24} />
  ),
  RERUN: <MdsRefreshRound className="w-full h-full stroke-[24]" size={24} />,
  TERMINATE: (
    <MdsCancelRound
      className="text-red-500 w-full h-full stroke-[24]"
      size={24}
    />
  ),
  QUEUED: <MdsHourglassRound className="w-full h-full stroke-[24]" size={24} />,
  RUNNING: <Loader className="!h-5 !w-5 animate-spin" />,
  CANCELLED: (
    <MdsPlayArrowRound className="w-full h-full stroke-[24]" size={24} />
  ),
};

export enum FLOW_TYPES {
  ALL_FLOWS = "all-flows",
  THIS_ORG = "current-org",
  THIS_WORKSPACE = "current-workspace",
  MY_FLOWS = "user-flows",
  STANDARD_FLOWS = "standard-flows",
  FAVORITES = "favourites",
}

export enum FLOW_SCOPE {
  ORGANIZATION = "ORGANIZATION",
  WORKSPACE = "WORKSPACE",
}

export enum FLOW_NODE_TYPES {
  CUSTOM_FLOW_NODE = "CUSTOM_FLOW_NODE",
  STANDARD_FLOW_NODE = "STANDARD_FLOW_NODE",
}

export enum DATASET_STATES {
  PENDING = "pending",
  SUCCESS = "success",
  STALE = "stale",
  ACTIVE = "active",
  PROCESSING = "download_processing",
}

export const UNIQUE_NODES = {
  TRANSFORM: "Transform",
  LLM: "LLM Prompt",
};

export const NODE_TYPES = {
  SOURCE_NODE: "SOURCE_NODE",
};

export const AUTO_SAVE_DEBOUNCE = 3000;

// show warning 1 min before timeout (10 mins) i.e, 530 seconds
export const TIMEOUT_WARNING_TIME = 530;

// show timed out modal after 590 seconds (10 seconds before timeout,
// because we want to call end session for the user)
export const TIMEOUT_MODAL_TIME = 590;

export enum TAXONOMY_MODES {
  RESTRICT_TO_EXISTING_TAXONOMY = "restrict_to_existing_taxonomy",
  INCLUDE_NEW_THEMES = "include_new_themes",
  INCLUDE_NEW_SUBTHEMES = "include_new_subthemes",
  INCLUDE_NEW_THEMES_AND_SUBTHEMES = "include_new_themes_and_subthemes",
}
