import { Box, Flex } from "@chakra-ui/react";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getOutgoers,
  NodeProps,
  useKeyPress,
  useReactFlow,
  useStoreApi
} from "reactflow";

import { useShowToast } from "@/components/toast";
import {
  getEditingAllowed,
  getNodeStatus,
  hideAllPanels,
  hidePanel,
  selectPanel,
  setContextMenu,
  showPanel,
  toggleLLMConfigPanel,
} from "@/features/workflow-studio/redux";
import { UNIQUE_NODES } from "@/features/workflow-studio/utils";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice";

import { useShowTransformPanel } from "../../hooks";
import {
  NodeType
} from "../../types";
import {
  NODE_STATUS,
  WORKFLOW_PANELS
} from "../../utils/constants";

import { NodeErrors } from "./node-errors";
import { NodeHandles } from "./node-handles";
import { NodeMetaData } from "./node-metaData";
import { NodeOutputTag } from "./node-output-tag";
import DataNodeToolbar from "./node-toolbar";

export const DataNode = ({
  id,
  data,
  isConnectable,
  selected,
}: NodeProps<NodeType>) => {
  // boolean state variable to set nodeToolbar to visible on hover
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useShowToast(undefined, undefined, true);
  const nodeConfigPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.NodeConfigurationPanel)
  );
  const dataPreviewPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.DataPreviewPanel)
  );

  const isWFEditAllowed = useAppSelector(getEditingAllowed);

  const dispatch = useAppDispatch();
  const { deleteElements, getNodes, getEdges, getNode } = useReactFlow();
  const store = useStoreApi();

  const { showTransformPanel } = useShowTransformPanel();
  const deletePressed = useKeyPress("Backspace");

  const hasConfig = useMemo(
    () => data.parameters.length > 0,
    [data.parameters]
  );

  // TODO: Need to change this later, need a way to indentify unique nodes
  const isTransformNode = useMemo(
    () => data.name === UNIQUE_NODES.TRANSFORM,
    [data.name]
  );

  const isLLMNode = useMemo(() => data.name === UNIQUE_NODES.LLM, [data.name]);

  const showToolbar = useMemo(() => {
    return isHovered && isWFEditAllowed;
  }, [isHovered, isWFEditAllowed]);

  // current node is assumed to be running if we get the status from the store
  const currentNodeRunStatus = useAppSelector(getNodeStatus(id));
  const shouldLockNode =
    currentNodeRunStatus?.nodeStatus === NODE_STATUS.RUNNING ||
    currentNodeRunStatus?.isLocked;

  const openPanels = useCallback(async () => {
    store.getState().resetSelectedElements();
    if (isLLMNode) {
      dispatch(hidePanel(WORKFLOW_PANELS.DataPreviewPanel));
      dispatch(toggleLLMConfigPanel({ show: true, nodeId: id }));
      return;
    }
    if (!hasConfig) {
      dispatch(hidePanel(WORKFLOW_PANELS.NodeConfigurationPanel));
      return;
    }

    if (isTransformNode) {
      setIsLoading(true);
      await showTransformPanel({ transformNodeUiId: id });
      setIsLoading(false);
      return;
    }

    dispatch(
      showPanel({
        panel: WORKFLOW_PANELS.NodeConfigurationPanel,
        nodeId: id,
      })
    );
  }, [dispatch, id, hasConfig, isTransformNode, isLLMNode, showTransformPanel]);

  const onNodeClick = useCallback(async () => {
    dispatch(setContextMenu(null));
 

    if (!selected) return;

    // if (!isTransformNode && !isLLMNode && !isWFEditAllowed) {
    //   // allow only UNIQUE_NODES to open when editing is not allowed
    //   return;
    // }

    const nodes = getNodes();
    const edges = getEdges();
    const currentNode = nodes.find((n) => n.id === id);
    if (!currentNode) return;

    const outgoingEdges = getOutgoers(currentNode, nodes, edges);
    const hasOutgoingEdges = outgoingEdges.length > 0;
    const isSuccessStatus = data.nodeStatus === NODE_STATUS.SUCCESS;

    if (hasOutgoingEdges && isSuccessStatus && isWFEditAllowed) {
      dispatch(
        openModal({
          modalType: ModalTypes.CONFIRM_NODE_EDIT,
          modalProps: {
            nodeData: data,
            onConfirmEdit: openPanels,
          },
        })
      );
      return;
    }

    await openPanels();
  }, [
    selected,
    dispatch,
    id,
    data,
    shouldLockNode,
    isWFEditAllowed,
    getNodes,
    getEdges,
    openPanels,
  ]);

  // if the current node is selected and the delete key is pressed, delete the node
  useEffect(() => {
    const shouldDeleteNode = selected && deletePressed;

    if (shouldDeleteNode) {
      const isCurrentNodeConfigOpen =
        nodeConfigPanel.nodeId === id || dataPreviewPanel.nodeId === id;
      if (isCurrentNodeConfigOpen) dispatch(hideAllPanels());
      deleteElements({ nodes: [{ id }] });
    }
  }, [
    selected,
    deletePressed,
    deleteElements,
    dispatch,
    dataPreviewPanel,
    nodeConfigPanel,
    id,
  ]);

  const onDelete = () => {
    if (nodeConfigPanel.nodeId === id || dataPreviewPanel.nodeId === id)
      dispatch(hideAllPanels());
    deleteElements({ nodes: [{ id }] });
  };

  return (
    <Box
      className={clsx(
        "relative group/groupCard !shadow-noblur",
        "rounded bg-white text-gray-800 border border-gray-700",
        selected && "border-2"
      )}
      w={"160px"}
      maxW={"200px"}
      minH={"120px"}
      maxH="160px"
      onClick={onNodeClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {showToolbar && (
        <DataNodeToolbar
          nodeStatus={currentNodeRunStatus?.nodeStatus as NODE_STATUS}
          isLocked={currentNodeRunStatus?.isLocked}
          onDelete={onDelete}
          id={id}
        />
      )}
      <NodeHandles isConnectable={isConnectable} />
      <Flex className={clsx(`w-full gap-3 p-3`)} direction="column">
        <NodeMetaData id={id} nodeData={data} isLoading={isLoading} />
        {data.isOutput && (
          <NodeOutputTag
            title={data.outputName}
            status={data.outputState as NODE_STATUS}
          />
        )}
      </Flex>
      <NodeErrors id={id} />
    </Box>
  );
};

export default DataNode;
